import api from "@/api/admin/index";

import { useAppStore } from "@/store/app";
import { showUserNotify } from "@/helpers/notify";

export default {
  async getGroupsData() {
    try {
      const groupsData = (await api.users.getGroupsData()).data;

      return groupsData;
    } catch (error) {
      console.error(error);
    }
  },
  async getUsersData(payload) {
    const appStore = useAppStore();

    appStore.loading = true;
    try {
      const usersData = (await api.users.getUsersData(payload)).data;

      appStore.loading = false;

      return usersData;
    } catch (error) {
      console.error(error);
    }
  },
  async createUserData(user) {
    try {
      await api.users.createUser(user);

      showUserNotify("success");
    } catch (error) {
      throw error;
    }
  },
  async updateUserData(user) {
    try {
      await api.users.updateUser(user.id, user);

      showUserNotify("success");
    } catch (error) {
      throw error;
    }
  },
  async removeUserData(id) {
    try {
      await api.users.removeUser(id);

      showUserNotify("success");
    } catch (error) {
      throw error;
    }
  },
};
