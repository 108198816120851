<template>
  <div class="p-container">
    <div class="grid">
      <div class="col-12 lg:col-8">
        <div class="content-wrapper post-title-wrapper">
          <div class="mb-3">
            <h4 class="mb-2">Заголовок публікації</h4>
            <InputText
              v-model="post.title"
              type="text"
              class="w-full"
              required
            />
          </div>
          <div>
            <div
              v-if="!IS_PUBLIC_SECTION"
              class="mb-2 flex justify-content-between"
            >
              <h4>Посилання</h4>
              <div v-if="!IS_CONTACT_SECTION" class="flex align-items-center">
                <Checkbox
                  binary
                  v-model="isExternalLink"
                  inputId="urlIsExternal"
                  name="urlIsExternal"
                />
                <label for="urlIsExternal" class="ml-2">
                  Посилання на сторонній ресурс
                </label>
              </div>
            </div>
            <div v-if="!IS_PUBLIC_SECTION" class="flex align-items-center">
              <span v-if="!IS_CONTACT_SECTION && !isExternalLink" class="mr-1">
                {{ itemPath }}
              </span>
              <InputText
                v-model="post.url"
                type="text"
                class="pl-1 line w-full"
              />
              <Button
                v-if="!IS_CONTACT_SECTION"
                v-show="!isExternalLink"
                raised
                icon="pi pi-copy"
                severity="secondary"
                class="ml-2"
                v-tooltip.bottom="'Копіювати посилання'"
                @click="copyURL()"
              />
            </div>
          </div>
        </div>
        <div v-if="IS_NEWS_TYPE" class="content-wrapper post-form__short-text">
          <h4 class="mb-2">Короткий опис публікації</h4>
          <div class="post__content post-form__short-text">
            <VueTrumbowyg
              v-model="post.short_text"
              class="post__content"
              name="short_text"
              :config="SHORT_EDITOR_CONFIG"
            />
          </div>
        </div>
        <div class="content-wrapper post-form__text">
          <h4 class="mb-2">Текст публікації</h4>
          <div class="post__content">
            <VueTrumbowyg
              v-model="post.text"
              class="post__content"
              name="text"
              :config="POSTS_SERVICE.createFullTextEditorConfig()"
            />
          </div>
        </div>
      </div>
      <div class="col-12 lg:col-4">
        <div class="content-wrapper">
          <div class="gap-3 flex flex-column justify-content-center">
            <ToggleButton
              v-model="isPublished"
              onLabel="Опублікувати"
              offLabel="Як чернетка"
              onIcon="pi pi-check"
              offIcon="pi pi-times"
              v-tooltip.bottom="'Статус публікації'"
            />
            <Button
              label="Зберегти"
              severity="primary"
              :disabled="submitIsDisabled"
              @click="sendPost(post)"
            />
          </div>
        </div>
        <div class="content-wrapper">
          <div class="mb-3">
            <h4 class="mt-0 mb-1">Розділ</h4>
            <Dropdown
              v-model="post.id_section"
              optionValue="id"
              optionLabel="name"
              placeholder="Розділ для публікації"
              class="w-full"
              :options="sections"
              :disabled="Boolean(route.params.section)"
            />
          </div>
          <div v-if="subSections" class="mb-3">
            <h4 class="mb-2">Підрозділ</h4>
            <Dropdown
              v-model="post.id_subsection"
              optionValue="id"
              optionLabel="title"
              placeholder="Оберіть підрозділ"
              class="w-full"
              :options="subSections"
            />
          </div>
          <div
            v-if="
              IS_NEWS_TYPE && post.id_subsection === IS_NEWS_GENERAL_SUBTYPE
            "
            class="mb-3"
          >
            <h4 class="mb-2">Тег новини</h4>
            <InputText v-model="post.options.tag" type="text" class="w-full" />
          </div>
          <div v-if="IS_LINK_TYPE" class="mb-3">
            <h4 class="mb-2">Позиція</h4>
            <InputText
              v-model.number="post.position"
              type="number"
              class="w-full"
            />
          </div>
        </div>
        <div v-if="IS_NEWS_TYPE" class="content-wrapper">
          <UploadImgPanel
            :title="'Головне зображення'"
            :id="'header_img'"
            :img="mainIMG"
            :imgSrc="post.img"
            @update:img="mainIMG = $event"
            @update:imgSrc="post.img = $event"
          />
        </div>
      </div>
    </div>
  </div>
  <div ref="reference"></div>
</template>

<script setup>
import { reactive, ref, onMounted, watch, computed } from "vue";

import { createFilters } from "@/helpers/filters";
import { showUserNotify } from "@/helpers/notify";
import { transliteration } from "@/helpers/transliteration";

import { useRoute, useRouter } from "vue-router";

import InputText from "primevue/inputtext";
import Button from "primevue/button";
import ToggleButton from "primevue/togglebutton";
import VueTrumbowyg from "vue-trumbowyg";
import Dropdown from "primevue/dropdown";
import UploadImgPanel from "@/components/admin/editor/UploadImgPanel.vue";
import Checkbox from "primevue/checkbox";

// trumbowyg styles
import "trumbowyg/dist/ui/trumbowyg.css";
import "trumbowyg/dist/plugins/colors/ui/trumbowyg.colors.min.css";
import "trumbowyg/dist/plugins/table/ui/trumbowyg.table.min.css";

// trumbowyg plugins
import "trumbowyg/dist/plugins/base64/trumbowyg.base64.min.js";
import "trumbowyg/dist/plugins/colors/trumbowyg.colors.min.js";
import "trumbowyg/dist/plugins/fontsize/trumbowyg.fontsize.min.js";
import "trumbowyg/dist/plugins/fontfamily/trumbowyg.fontfamily.min.js";
import "trumbowyg/dist/plugins/lineheight/trumbowyg.lineheight.min.js";
import "trumbowyg/dist/plugins/indent/trumbowyg.indent.min.js";
import "trumbowyg/dist/plugins/table/trumbowyg.table.min.js";
import "trumbowyg/dist/plugins/upload/trumbowyg.upload.min.js";
import "trumbowyg/dist/plugins/resizimg/trumbowyg.resizimg.min.js";
import "trumbowyg/dist/langs/ua.min.js";
import "jquery-resizable-dom/dist/jquery-resizable";

import TYPES from "@/config/types.yml";
import FILTERS from "@/config/filters.yml";
import EDITOR_CONFIG from "@/config/editor.yml";

import POSTS_SERVICE from "@/services/admin/posts";
import SECTIONS_SERVICE from "@/services/admin/sections";

const SHORT_EDITOR_CONFIG = EDITOR_CONFIG.short_editor_config;

const route = useRoute();
const router = useRouter();

const IS_LINK_TYPE = TYPES.post.link == route.params.type;
const IS_NEWS_TYPE = TYPES.post.news == route.params.type;
const IS_NEWS_GENERAL_SUBTYPE = TYPES.news.general;
const IS_CONTACT_SECTION = TYPES.section.contact == route.params.section;
const IS_PUBLIC_SECTION = TYPES.section.public == route.params.section;

// POST
const filtersData = reactive({
  filterByPostID: {
    field: FILTERS.posts.filterByID,
    value: "",
  },
  filters: {},
});

const post = reactive({
  id_type: null,
  id_subsection: null,
  id_section: null,
  url_is_external: 0,
  position: 1,
  url: null,
  title: "",
  short_text: null,
  text: null,
  img: null,
  is_published: null,
  is_blocked: null,
  options: null,
});

const postURL = ref("");
const mainIMG = ref(null);
const urlIsExternal = ref(false);
const isPublished = ref(false);
const isExternalLink = ref(false);
const reference = ref(null);
const itemPath = computed(() => {
  if (!IS_CONTACT_SECTION && !urlIsExternal.value && post.id_section) {
    let temp = "";

    temp += sections.value.find(
      (section) => section.id === post.id_section
    )?.url;

    if (subSections.value) {
      let subSection = subSections.value.find(
        (section) => section.id === post.id_subsection
      );

      temp += subSection ? "/" + subSection.url : "";
    }

    return temp + "/";
  }

  return "";
});

watch(
  () => post.title,
  (newTitle) => {
    if (!IS_CONTACT_SECTION) {
      newTitle = newTitle.trim();

      if (!isExternalLink.value) {
        post.url = transliteration(newTitle);
        post.title = newTitle;
      }
    }
  }
);

function parseOptions() {
  try {
    let options = post.options ? JSON.parse(post.options) : {};

    if (!options || typeof options !== "object") {
      options = {};
    }

    if (!options.tag) {
      options.tag = "";
    }

    post.options = options;
  } catch (error) {
    showUserNotify("error", error);
  }
}

async function sendPost(post) {
  try {
    post.is_published = isPublished.value ? 1 : 0;
    post.url_is_external = isExternalLink.value ? 1 : 0;
    post.options = JSON.stringify(post.options);

    let postData = {
      data: post,
      img: mainIMG.value,
    };

    if (route.params.id) {
      await POSTS_SERVICE.updatePostData(route.params.id, postData);
    } else {
      await POSTS_SERVICE.createPostData(postData);
    }

    router.go(-1);
  } catch (error) {
    console.error(error);
  }
}

const submitIsDisabled = computed(() => {
  return (
    !post.title || !post.id_type || (subSections.value && !post.id_subsection)
  );
});

// SECTIONS
const sections = ref([]);
const subSections = ref(null);

onMounted(async () => {
  if (route.params.id) {
    filtersData.filterByPostID.value = route.params.id;

    filtersData.filters = createFilters(filtersData.filters, [
      filtersData.filterByPostID,
    ]);

    let data = await POSTS_SERVICE.getPostsData(filtersData.filters);

    if (!data.rows) {
      router.push({ name: "admin-news" });

      showUserNotify("warn", "Публікацію не знайдено");

      return;
    }

    Object.assign(post, data.rows[0]);

    isPublished.value = !!post.is_published;
    isExternalLink.value = !!post.url_is_external;
    postURL.value = post.url;
  }

  post.id_section = parseInt(route.params.section);
  post.id_type = parseInt(route.params.type);
  parseOptions();

  sections.value = await SECTIONS_SERVICE.getSectionsData({});
  subSections.value = sections.value.find(
    (section) => section.id === post.id_section
  )?.sub_sections;
});

function copyURL() {
  try {
    let fullURL = itemPath.value + post.url;

    const storage = document.createElement("textarea");
    storage.value = fullURL;

    reference.value.appendChild(storage);

    storage.select();
    storage.setSelectionRange(0, 99999);
    document.execCommand("copy");

    reference.value.removeChild(storage);

    showUserNotify("success", "Посилання скопійовано");
  } catch (e) {
    showUserNotify("warn", "Помилка копіювання посилання");
  }
}
</script>
