<template>
  <header class="page-header">
    <div class="p-container">
      <div class="grid">
        <div
          class="site-sublogo flex justify-content-between lg:block col-12 lg:col-3"
        >
          <div>
            <div class="site-sublogo__title">
              <span> gov.ua </span>
            </div>
            <div class="site-sublogo__subtitle">
              <span> Державні сайти України </span>
            </div>
          </div>
          <div class="show-navbar lg:hidden">
            <input type="checkbox" v-model="showMobileMenu" />
            <div
              class="hamburger-lines flex flex-column justify-content-between"
            >
              <span class="line line1"></span>
              <span class="line line2"></span>
              <span class="line line3"></span>
            </div>
          </div>
        </div>
        <router-link
          class="block site-logo col-12 lg:col-6"
          :to="{ name: 'home' }"
        >
          <img class="site-logo__img" src="@/assets/img/emblem.svg" />
          <h1 class="site-logo__title">
            Управління охорони здоров`я Черкаської ОДА
          </h1>
          <h3 class="site-logo__subtitle">Офіційний вебпортал</h3>
        </router-link>
        <div
          class="site-social flex justify-content-between lg:block col-12 lg:col-3"
        >
          <div
            class="site-social__links flex align-items-center justify-content-end gap-3"
          >
            <a
              v-for="contact in headerContacts"
              :key="contact.id"
              :href="contact.url"
            >
              <i :class="contact.id_custom"></i>
            </a>
          </div>
          <div class="site-social__visual-impairment hidden lg:block">
            <VisualImpairment />
          </div>
          <div class="site-social__search">
            <button
              class="show-search-btn"
              @click="showSearchForm = !showSearchForm"
            >
              <span>Пошук</span>
            </button>
          </div>
        </div>
      </div>
      <PageNavBar
        v-model:showMobileMenu="showMobileMenu"
        :class="['lg:block', showMobileMenu ? 'block' : 'hidden']"
      />
      <SearchForm
        v-if="showSearchForm"
        v-model:showSearchForm="showSearchForm"
      />
    </div>
  </header>
</template>

<script setup>
import { ref, watch, inject, computed } from "vue";

import PageNavBar from "./header/PageNavBar.vue";
import SearchForm from "./header/SearchForm.vue";
import VisualImpairment from "./VisualImpairment.vue";

const SITE_FACEBOOK_ID = "site_facebook";
const SITE_INSTAGRAM_ID = "site_instagram";

// NAVBAR
const showMobileMenu = ref(false);

watch(showMobileMenu, (newVal) => {
  let body = document.getElementsByTagName("body")[0];

  body.style.overflow = newVal ? "hidden" : "auto";
});

// SEARCH
const showSearchForm = ref(false);

// SITE CONTACTS
const siteContacts = inject("siteContacts");
const headerContacts = computed(() =>
  siteContacts.value.filter(
    (item) =>
      item.id_custom === SITE_FACEBOOK_ID ||
      item.id_custom === SITE_INSTAGRAM_ID
  )
);
</script>
