<template>
  <PageBreadcrumb :lastBreadcrumb="lastBreadcrumb" />

  <main class="section-page">
    <section class="section section__all-subsection">
      <div class="p-container">
        <h2 class="section__header content__header hidden lg:block">
          {{ lastBreadcrumb.label }}
        </h2>
        <ul class="section__subsection-list">
          <li v-for="subsection in section.sub_sections" :key="subsection.id">
            <a v-if="!!subsection.url_is_external" :href="subsection.url">
              <span>{{ subsection.title }}</span>
            </a>
            <router-link v-else :to="`${section.url}/${subsection.url}`">
              <span>
                {{ subsection.title }}
              </span>
            </router-link>
          </li>
        </ul>
      </div>
    </section>
  </main>
</template>

<script setup>
import { reactive, onMounted, watch, computed } from "vue";

import { useRoute, useRouter } from "vue-router";

import { createFilters } from "@/helpers/filters";
import { changeDocumentTitle } from "@/helpers/site";

import PageBreadcrumb from "@/components/public/page/PageBreadcrumb.vue";

import FILTERS from "@/config/filters.yml";

import SECTIONS_SERVICE from "@/services/public/sections";

const router = useRouter();
const route = useRoute();
watch(
  () => route.params,
  async (newParams) => {
    if (newParams.section) {
      await getSection(newParams);
    }
  },
  { deep: true }
);

// SECTION
const section = reactive({});
const sectionFilters = reactive({
  filterBySectionURL: {
    field: FILTERS.sections.filterBySectionURL,
    value: null,
  },
});

async function getSection(params) {
  sectionFilters.filterBySectionURL.value = "/" + params.section;

  let sectionData = await SECTIONS_SERVICE.getSectionsData(
    createFilters({}, [sectionFilters.filterBySectionURL])
  );

  if (sectionData) {
    let data = sectionData[0];
    changeDocumentTitle(data.name);
    Object.assign(section, data);
  } else {
    router.push({ name: "404-page" });
  }
}

onMounted(async () => {
  try {
    await getSection(route.params);
  } catch (error) {
    console.error(error);
  }
});

// BREADCRUMB
const lastBreadcrumb = computed(() => {
  if (section) {
    return { label: section.name };
  }

  return {};
});
</script>
