<template>
  <Skeleton
    v-if="appStore.loading"
    height="70px"
    class="mt-3"
    borderRadius="24px"
  />
  <Paginator
    v-show="!appStore.loading && totalRows"
    class="p-0 mt-3"
    :rows="20"
    :totalRecords="totalRows"
    :rowsPerPageOptions="[20, 50, 90]"
    @page="changePage"
  />
</template>

<script setup>
import { defineProps, defineEmits } from "vue";
import { useAppStore } from "@/store/app";

import Paginator from "primevue/paginator";
import Skeleton from "primevue/skeleton";

const appStore = useAppStore();

const props = defineProps({
  filters: Object,
  totalRows: Number,
});
const emits = defineEmits(["getItemsByPage", "update:filters"]);

function changePage(event) {
  let filters = { ...props.filters };

  filters.page = event.page + 1;
  filters.pageSize = event.rows;

  window.scrollTo({ top: 0, behavior: "smooth" });

  emits("update:filters", filters);
  emits("getItemsByPage");
}
</script>
