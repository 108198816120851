<template>
  <PageHeader />

  <router-view></router-view>

  <PageFooter />
</template>

<script setup>
import { ref, onMounted, provide, reactive } from "vue";
import { createFilters } from "@/helpers/filters";

import PageHeader from "@/components/public/page/PageHeader.vue";
import PageFooter from "@/components/public/page/PageFooter.vue";

import TYPES from "@/config/types.yml";
import FILTERS from "@/config/filters.yml";

import SECTIONS_SERVICE from "@/services/public/sections";
import POSTS_SERVICE from "@/services/public/posts";

// MENU
const menuItems = ref(null);

onMounted(async () => {
  try {
    menuItems.value = await SECTIONS_SERVICE.getSectionsData({});
  } catch (error) {
    console.error(error);
  }
});

provide("siteMenuItems", menuItems);

// SITE CONTACTS
const siteContacts = ref([]);

const postFilters = reactive({
  filterByTypeID: {
    field: FILTERS.posts.filterByTypeID,
    value: TYPES.post.site_contact,
  },
  filterBySectionID: {
    field: FILTERS.posts.filterBySectionID,
    value: TYPES.section.main,
  },
  filterByPublished: {
    field: FILTERS.posts.filterByPublished,
    value: 1,
  },
  filters: { pageSize: -1 },
});

onMounted(async () => {
  try {
    let filters = createFilters(postFilters.filters, [
      postFilters.filterByPublished,
      postFilters.filterBySectionID,
      postFilters.filterByTypeID,
    ]);

    let data = await POSTS_SERVICE.getPostsData(filters);
    if (data.rows) {
      siteContacts.value = data.rows;
    }
  } catch (error) {
    console.error(error);
  }
});

provide("siteContacts", siteContacts);
</script>
