<template>
  <div class="p-container">
    <div class="content-wrapper">
      <DataTable
        stripedRows
        dataKey="id"
        :filterDisplay="IS_FEEDBACK_TYPE ? '' : 'row'"
        class="p-datatable-sm"
        :loading="appStore.loading"
        rowHover
        :value="posts"
      >
        <template #empty> Дані відсутні </template>
        <template #header>
          <div
            class="flex flex-wrap align-items-center justify-content-between px-2"
          >
            <span class="table-header">{{ route.meta.title }}</span>

            <Button
              v-if="!IS_FEEDBACK_TYPE"
              v-tooltip.bottom="
                `Додати ${FORMS[route.meta.type]?.label ?? 'публікацію'}`
              "
              rounded
              raised
              icon="pi pi-plus"
              severity="info"
              @click="createPost"
            />
          </div>
        </template>
        <Column
          field="title"
          headerStyle="width: 40%"
          :showFilterMenu="false"
          :showClearButton="false"
        >
          <template #header>
            <span class="m-auto">Заголовок</span>
          </template>
          <!-- <template #filter>
            <PageTableColumnFilterPanel
              v-model:filter="postFilters.filterByTitle"
              :placeholder="'Введіть заголовок'"
              :key="filterKey"
              :showSearchBtn="
                postFilters.filterByTitle.value &&
                postFilters.filterByTitle.value !== '' &&
                posts.length == 0
              "
              @applyFilters="getPosts(postFilters.filterByTitle)"
            />
          </template> -->
        </Column>
        <Column :showFilterMenu="false" :showClearButton="false">
          <template #header>
            <span class="m-auto">Розділ</span>
          </template>
          <template #filter>
            <PageTableColumnFilterPanel
              v-if="sections && sections[0]?.sub_sections"
              v-model:filter="postFilters.filterBySubSectionID"
              :placeholder="'Усі'"
              :labelOption="'title'"
              :filterItems="sections[0]?.sub_sections"
              :key="filterKey"
              @applyFilters="getPosts(postFilters.filterBySubSectionID)"
            />
          </template>
          <template #body="{ data }">
            {{
              `${data?.post_section?.name} ${
                data?.post_subsection.title
                  ? " | " + data?.post_subsection.title
                  : ""
              }`
            }}
          </template>
        </Column>
        <Column
          bodyStyle="text-align: center; overflow: visible"
          :showFilterMenu="false"
          :showClearButton="false"
        >
          <template #header>
            <span class="m-auto">Дата публікації</span>
          </template>
          <template #filter>
            <PageTableColumnFilterPanel
              v-model:sorting="postFilters.sortByDate"
              :key="filterKey"
              @applySorted="getPosts(postFilters.sortByDate)"
            />
          </template>
          <template #body="{ data }">
            {{ data.updated_at }}
          </template>
        </Column>
        <Column
          bodyStyle="text-align: center; overflow: visible"
          headerStyle="width: 10%"
          :showFilterMenu="false"
          :showClearButton="false"
        >
          <template #header>
            <span class="m-auto">Статус</span>
          </template>
          <template #body="{ data }">
            <Tag
              :severity="!!data.is_published ? 'success' : 'warning'"
              :value="!!data.is_published ? 'Опубліковано' : 'Чернетка'"
              rounded
            ></Tag>
          </template>
          <template #filter>
            <PageTableColumnFilterPanel
              v-model:filter="postFilters.filterByStatusID"
              :placeholder="'Усі'"
              :filterItems="postFilters.statuses"
              :key="filterKey"
              @applyFilters="getPosts(postFilters.filterByStatusID)"
            />
          </template>
        </Column>
        <Column
          bodyStyle="text-align: center; overflow: visible"
          headerStyle="width: 10%"
          :showFilterMenu="false"
          :showClearButton="false"
        >
          <template #filter>
            <PageTableColumnFilterPanel
              :showOptions="false"
              @clearFilters="clearFilters"
            />
          </template>
          <template #body="{ data }">
            <PageItemControlPanel
              :tooltipEdit="`Редагувати ${data.title}`"
              :tooltipRemove="`Видалити  ${data.title}`"
              @edit="updatePost(data)"
              @remove="removePost(data.id)"
            />
          </template>
        </Column>
      </DataTable>
    </div>
    <PageItemsPaginator
      v-if="!IS_FEEDBACK_TYPE"
      v-model:filters="postFilters.filters"
      :key="paginatorKey"
      :totalRows="postsData.total_rows"
      @getItemsByPage="getPosts()"
    />
  </div>
</template>

<script setup>
import { ref, reactive, onMounted, computed } from "vue";
import { createFilters, clearFiltersList } from "@/helpers/filters";
import { useAppStore } from "@/store/app";
import { useRoute, useRouter } from "vue-router";
import { showModal } from "@/helpers/dialog";

import PageItemsPaginator from "@/components/admin/page/PageItemsPaginator.vue";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import PageItemControlPanel from "@/components/admin/page/PageItemControlPanel.vue";
import PageTableColumnFilterPanel from "@/components/admin/page/PageTableColumnFilterPanel.vue";
import Button from "primevue/button";
import Tag from "primevue/tag";
import ContactForm from "@/components/admin/posts/ContactForm.vue";
import ManagerForm from "@/components/admin/posts/ManagerForm.vue";
import SliderForm from "@/components/admin/posts/SliderForm.vue";
import LinkForm from "@/components/admin/posts/LinkForm.vue";
import SiteContactForm from "@/components/admin/posts/SiteContactForm.vue";

import TYPES from "@/config/types.yml";
import FILTERS from "@/config/filters.yml";

import POSTS_SERVICE from "@/services/admin/posts";
import SECTIONS_SERVICE from "@/services/admin/sections";

const appStore = useAppStore();
const route = useRoute();
const router = useRouter();

const IS_LINK_TYPE = TYPES.post.link === route.meta.type;
const IS_CONTACT_TYPE = TYPES.post.contact === route.meta.type;
const IS_NEWS_TYPE = TYPES.post.news === route.meta.type;
const IS_PAGE_TYPE = TYPES.post.page === route.meta.type;
const IS_FEEDBACK_TYPE = TYPES.post.feedback === route.meta.type;

const FORMS = {
  [TYPES.post.contact]: {
    form: ContactForm,
    label: "контакт управління",
  },
  [TYPES.post.manager]: {
    form: ManagerForm,
    label: "співробітника",
  },
  [TYPES.post.slider]: {
    form: SliderForm,
    label: "слайдер",
  },
  [TYPES.post.link]: {
    form: LinkForm,
    label: "корисне посилання",
  },
  [TYPES.post.site_contact]: {
    form: SiteContactForm,
    label: "контакт",
  },
};

// POST
async function createPost() {
  if (IS_PAGE_TYPE || IS_NEWS_TYPE || IS_FEEDBACK_TYPE) {
    router.push({
      name: "admin-post-editor",
      params: {
        section: route.meta.section,
        type: route.meta.type,
      },
    });
  } else {
    showModal(FORMS[route.meta.type].form, {
      props: {
        header: `Додати ${FORMS[route.meta.type].label}`,
      },
      data: {
        post: null,
      },
      emits: {
        onUpdate: async (payload) => {
          try {
            await POSTS_SERVICE.createPostData(payload.post);
            await getPosts();

            payload.dialog.close();
          } catch (error) {
            console.error(error);
          }
        },
      },
    });
  }
}

async function updatePost(post) {
  if (IS_PAGE_TYPE || IS_NEWS_TYPE || IS_FEEDBACK_TYPE) {
    router.push({
      name: "admin-post-editor",
      params: {
        section: route.meta.section,
        type: route.meta.type,
        id: post.id,
      },
    });
  } else {
    showModal(FORMS[route.meta.type].form, {
      props: {
        header: `Редагувати ${FORMS[route.meta.type].label}`,
      },
      data: {
        post: post,
      },
      emits: {
        onUpdate: async (payload) => {
          try {
            await POSTS_SERVICE.updatePostData(post.id, payload.post);
            await getPosts();

            payload.dialog.close();
          } catch (error) {
            console.error(error);
          }
        },
      },
    });
  }
}

async function removePost(id) {
  try {
    await POSTS_SERVICE.removePostData(id);

    await getPosts();
  } catch (error) {
    console.error(error);
  }
}

// POSTS
const sections = ref([]);

const postsData = reactive({});
const posts = computed(() => {
  let search = postFilters.filterByTitle.value
    ? postFilters.filterByTitle.value
    : "";

  if (postsData.rows) {
    let posts = postsData.rows.filter((item) => {
      return item.title.toLowerCase().indexOf(search.toLowerCase()) > -1;
    });

    return posts;
  }

  return [];
});
const filterKey = ref(0);
const paginatorKey = ref(0);

const postFilters = reactive({
  filterByTitle: {
    field: FILTERS.posts.filterByTitle,
    value: null,
  },
  filterByTypeID: {
    field: FILTERS.posts.filterByTypeID,
    value: null,
  },
  filterBySectionID: {
    field: FILTERS.posts.filterBySectionID,
    value: null,
  },
  filterBySubSectionID: {
    field: FILTERS.posts.filterBySubSectionID,
    value: null,
  },
  filterByPublished: {
    field: FILTERS.posts.filterByPublished,
    value: null,
  },
  sortByPosition: {
    field: FILTERS.posts.sortByPosition,
    sort: null,
  },
  sortByDate: {
    field: FILTERS.posts.sortByDate,
    sort: FILTERS.sorting.DESC,
  },
  statuses: [
    { id: 0, name: "Чернетка" },
    { id: 1, name: "Опубліковано" },
  ],
  filters: { pageSize: 20 },
});

const sectionsFilter = reactive({
  filterBySectionID: {
    field: FILTERS.sections.filterBySectionID,
    value: null,
  },
});

async function getPosts(filter) {
  let filtered = [postFilters.filterBySectionID, postFilters.filterByTypeID];

  if (filter) {
    filtered.push(filter);
  }

  if (IS_LINK_TYPE || IS_CONTACT_TYPE) {
    postFilters.sortByPosition.sort = FILTERS.sorting.ASC;
    filtered.push(postFilters.sortByPosition);
  } else {
    filtered.push(postFilters.sortByDate);
  }

  postFilters.filters = createFilters(postFilters.filters, filtered);

  Object.assign(
    postsData,
    await POSTS_SERVICE.getPostsData(postFilters.filters)
  );
}

async function clearFilters() {
  filterKey.value++;
  paginatorKey.value++;

  postFilters.filterByTitle.value = null;
  postFilters.filterBySectionID.value = route.meta.section;
  postFilters.filterByTypeID.value = route.meta.type;

  postFilters.filters = clearFiltersList();

  await getPosts();
}

onMounted(async () => {
  document.title = route.meta.title;

  postFilters.filterByTypeID.value = route.meta.type;
  postFilters.filterBySectionID.value = route.meta.section;
  sectionsFilter.filterBySectionID.value = route.meta.section;

  sections.value = await SECTIONS_SERVICE.getSectionsData(
    createFilters({}, [sectionsFilter.filterBySectionID])
  );

  await getPosts();
});
</script>
