<template>
  <div
    class="auth-page h-screen h-full flex justify-content-center align-items-center p-fluid"
  >
    <div class="auth-form__wrapper content-wrapper text-center">
      <h2>Панель керування</h2>

      <AuthForm />
    </div>
  </div>
</template>

<script setup>
import { onMounted } from "vue";

import { useRouter } from "vue-router";

import AuthForm from "@/components/admin/auth/AuthForm.vue";

import AURH_SERVICE from "@/services/admin/auth";

const router = useRouter();

onMounted(() => {
  if (AURH_SERVICE.adminTokenIsValid()) {
    router.push({ name: "admin-news" });
  }
});
</script>
