<template>
  <div class="latest-news__item latest-news__card block">
    <figure>
      <img v-if="news.img" :src="news.img" />
      <img v-else src="@/assets/img/default-news.png" />
      <figcaption>
        <div
          class="flex flex-column md:flex-row align-items-start md:align-center justify-content-between"
        >
          <span class="post__date"> {{ dateParse.parseShortDate(news) }} </span>
          <span v-if="newsTag" class="news-tag">{{ newsTag }}</span>
        </div>

        <h2>
          {{ news.title }}
        </h2>
      </figcaption>
    </figure>
  </div>
</template>

<script setup>
import { defineProps, computed } from "vue";

import dateParse from "@/helpers/date";

const props = defineProps({
  news: Object,
});

const newsTag = computed(() => {
  if (props.news.options) {
    let options = JSON.parse(props.news.options);

    return options.tag ? options.tag : null;
  }

  return null;
});
</script>
