<template>
  <div class="search-form__wrapper">
    <div class="p-container">
      <form class="search-form" @submit.prevent="searching">
        <label for="search-field" class="search-form__label block">
          Ключові слова
        </label>
        <button
          type="button"
          class="search-form__close"
          @click="emits('update:showSearchForm', false)"
        >
          <i class="search-form__close-icon"></i>
        </button>
        <div class="lg:flex">
          <input
            type="text"
            id="search-field"
            class="search-form__field"
            v-model="searchValue"
            @change="trim"
          />

          <button
            class="search-form__submit"
            type="submit"
            :disabled="disabledSearch"
          >
            Знайти
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script setup>
import { defineEmits, onMounted, onUnmounted, ref, computed } from "vue";

import { useRoute, useRouter } from "vue-router";

const route = useRoute();
const router = useRouter();
const emits = defineEmits(["update:showSearchForm"]);

// SEARCH
function trim() {
  searchValue.value = searchValue.value.trim();
}

const searchValue = ref(null);
const searchQuery = computed(() => {
  if (searchValue.value) {
    return searchValue.value.trim();
  }
  return null;
});
const disabledSearch = computed(() => {
  if (searchQuery.value && searchQuery.value.length > 2) {
    return false;
  }

  return true;
});

async function searching() {
  if (route.name !== "search") {
    router.push({ name: "search", query: { search: searchValue.value } });
  } else {
    router.replace({
      query: { search: searchValue.value },
    });
  }

  emits("update:showSearchForm", false);
}

// SEARCH FORM
function closeSearchOnClickOutside(ev) {
  let classList = [];

  if (ev.target.classList.value) {
    classList = classList.concat(ev.target.classList.value.split(" "));
  }

  if (ev.target.parentElement.classList.value) {
    classList = classList.concat(
      ev.target.parentElement.classList.value.split(" ")
    );
  }

  if (
    !classList.some(
      (el) =>
        el === "search-form__label" ||
        el === "search-form__wrapper" ||
        el === "search-form__field" ||
        el === "search-form__submit" ||
        el === "show-search-btn"
    )
  ) {
    emits("update:showSearchForm", false);
  }
}

onMounted(() => {
  document.addEventListener("click", closeSearchOnClickOutside);
});

onUnmounted(() => {
  document.removeEventListener("click", closeSearchOnClickOutside);
});
</script>
