<template>
  <div class="p-container">
    <div class="content-wrapper">
      <DataTable
        stripedRows
        dataKey="id"
        filterDisplay="row"
        class="p-datatable-sm"
        :loading="appStore.loading"
        rowHover
        :value="users"
      >
        <template #empty> Дані відсутні </template>
        <template #header>
          <div
            class="flex flex-wrap align-items-center justify-content-between px-2"
          >
            <span class="table-header">Користувачі</span>
            <Button
              label="Додати користувача"
              severity="info"
              @click="createUser"
            />
          </div>
        </template>
        <Column field="id" bodyStyle="text-align: center; overflow: visible">
          <template #header>
            <span class="m-auto">ID</span>
          </template>
        </Column>
        <Column
          optionLabel="name"
          bodyStyle="text-align: center;"
          headerStyle="width: 40%"
          :showFilterMenu="false"
          :showClearButton="false"
        >
          <template #header>
            <span class="m-auto">П.І.Б</span>
          </template>
          <template #filter>
            <PageTableColumnFilterPanel
              v-model:filter="userFilters.filterBySurname"
              :placeholder="'Введіть прізвище'"
              :key="filterKey"
              :showSearchBtn="
                userFilters.filterBySurname.value &&
                userFilters.filterBySurname.value !== '' &&
                users.length == 0
              "
              @applyFilters="getUsers(userFilters.filterBySurname)"
            />
          </template>
          <template #body="{ data }">
            {{ `${data?.surname} ${data?.firstname} ${data?.lastname}` }}
          </template>
        </Column>
        <Column
          optionLabel="group"
          bodyStyle="text-align: center; overflow: visible"
          :showFilterMenu="false"
          :showClearButton="false"
        >
          <template #header>
            <span class="m-auto">Група</span>
          </template>
          <template #filter>
            <PageTableColumnFilterPanel
              v-model:filter="userFilters.filterByUserGroupID"
              :placeholder="'Усі'"
              :filterItems="groups"
              :key="filterKey"
              @applyFilters="getUsers(userFilters.filterByUserGroupID)"
            />
          </template>
          <template #body="{ data }">
            {{ data?.user_group?.name }}
          </template>
        </Column>
        <Column
          bodyStyle="text-align: center; overflow: visible"
          headerStyle="width: 10%"
          :showFilterMenu="false"
          :showClearButton="false"
        >
          <template #header>
            <span class="m-auto">Статус</span>
          </template>
          <template #body="{ data }">
            <Tag
              :severity="!!data.is_active ? 'success' : 'warning'"
              :value="!!data.is_active ? 'Активний' : 'Відключений'"
              rounded
            ></Tag>
          </template>
          <template #filter>
            <PageTableColumnFilterPanel
              v-model:filter="userFilters.filterByStatusID"
              :placeholder="'Усі'"
              :filterItems="userFilters.statuses"
              :key="filterKey"
              @applyFilters="getUsers(userFilters.filterByStatusID)"
            />
          </template>
        </Column>
        <Column
          bodyStyle="text-align: center; overflow: visible"
          headerStyle="width: 10%"
          :showFilterMenu="false"
          :showClearButton="false"
        >
          <template #filter>
            <PageTableColumnFilterPanel
              :showOptions="false"
              @clearFilters="clearFilters"
            />
          </template>
          <template #body="{ data }">
            <PageItemControlPanel
              :tooltipEdit="`Редагувати ${`${data.surname}
            ${data.firstname}`}`"
              :tooltipRemove="`Видалити ${`${data.surname}
            ${data.firstname}`}`"
              @edit="updateUser(data)"
              @remove="removeUser(data.id)"
            />
          </template>
        </Column>
      </DataTable>
    </div>
    <PageItemsPaginator
      v-model:filters="userFilters.filters"
      :key="paginatorKey"
      :totalRows="usersData.total_rows"
      @getItemsByPage="getUsers()"
    />
  </div>
</template>

<script setup>
import { ref, reactive, onMounted, computed } from "vue";
import { createFilters, clearFiltersList } from "@/helpers/filters";
import { showModal } from "@/helpers/dialog";
import { useAppStore } from "@/store/app";
import { useRoute } from "vue-router";

import PageItemsPaginator from "@/components/admin/page/PageItemsPaginator.vue";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import PageItemControlPanel from "@/components/admin/page/PageItemControlPanel.vue";
import PageTableColumnFilterPanel from "@/components/admin/page/PageTableColumnFilterPanel.vue";
import Button from "primevue/button";
import UserForm from "@/components/admin/users/UserForm.vue";
import Tag from "primevue/tag";

import FILTERS from "@/config/filters.yml";

import USERS_SERVICE from "@/services/admin/users";

const appStore = useAppStore();
const route = useRoute();

// USER
async function createUser() {
  showModal(UserForm, {
    props: {
      header: "Додати користувача",
    },
    data: {
      user: null,
    },
    emits: {
      onUpdate: async (payload) => {
        try {
          await USERS_SERVICE.createUserData(payload.user);

          await getUsers();
          payload.dialog.close();
        } catch (error) {
          console.error(error);
        }
      },
    },
  });
}

async function updateUser(user) {
  showModal(UserForm, {
    props: {
      header: "Редагувати користувача",
    },
    data: {
      user: user,
    },
    emits: {
      onUpdate: async (payload) => {
        try {
          await USERS_SERVICE.updateUserData(payload.user);

          await getUsers();
          payload.dialog.close();
        } catch (error) {
          console.error(error);
        }
      },
    },
  });
}

async function removeUser(id) {
  try {
    await USERS_SERVICE.removeUserData(id);

    await getUsers();
  } catch (error) {
    console.error(error);
  }
}

// USERS
const groups = ref([]);
onMounted(async () => {
  groups.value = await USERS_SERVICE.getGroupsData();
});

const usersData = reactive({});
const users = computed(() => {
  let search = userFilters.filterBySurname.value
    ? userFilters.filterBySurname.value
    : "";

  if (usersData.rows) {
    let users = usersData.rows.filter((item) => {
      return item.surname.toLowerCase().indexOf(search.toLowerCase()) > -1;
    });

    return users;
  }

  return [];
});
const filterKey = ref(0);
const paginatorKey = ref(0);

const userFilters = reactive({
  filterByUserGroupID: {
    field: FILTERS.users.filterByUserGroupID,
    value: null,
  },
  filterByStatusID: {
    field: FILTERS.users.filterByStatusID,
    value: null,
  },
  filterBySurname: {
    field: FILTERS.users.filterBySurname,
    value: null,
  },
  statuses: [
    { id: 0, name: "Відключений" },
    { id: 1, name: "Активний" },
  ],
  filters: { pageSize: 20 },
});

async function getUsers(filter) {
  let filtered = filter ? [filter] : [];
  userFilters.filters = createFilters(userFilters.filters, filtered);

  Object.assign(
    usersData,
    await USERS_SERVICE.getUsersData(userFilters.filters)
  );
}

async function clearFilters() {
  filterKey.value++;
  paginatorKey.value++;

  userFilters.filterBySurname.value = null;
  userFilters.filters = clearFiltersList();

  await getUsers();
}

onMounted(async () => {
  document.title = route.meta.title;
  await getUsers();
});
</script>
