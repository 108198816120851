<template>
  <PageBreadcrumb v-if="singleNews?.id" :lastBreadcrumb="lastBreadcrumb" />

  <main class="news-page">
    <div v-if="singleNews?.id" class="p-container">
      <div class="grid">
        <article class="col-12 lg:col-8">
          <div class="news-page__header">
            <h1 class="news-post__title post__title">
              {{ singleNews.title }}
            </h1>
            <span v-if="singleDate" class="news-page__date post__date block">
              {{ dateParse.parseLongDate(singleNews) }}
            </span>
            <span v-if="newsTag" class="news-tag">{{ newsTag }}</span>
            <h2
              class="news-page__subtitle post__subtitle"
              v-html="singleNews.short_text"
            ></h2>
          </div>
          <img v-if="singleNews.img" :src="singleNews.img" class="post__img" />
          <img v-else src="@/assets/img/default-news.png" class="post__img" />
          <div
            class="news-post__content post__content"
            v-html="singleText"
          ></div>
          <div class="news-post__footer post__footer">
            <ShareNewsBtn :title="singleNews.title" />
            <div class="news-page__paginator flex justify-content-between">
              <div>
                <a
                  v-if="singleNews.url_prev"
                  class="previos-news"
                  :href="singleNews.url_prev"
                >
                  <span>Попередня</span>
                </a>
              </div>
              <div>
                <a
                  v-if="singleNews.url_next"
                  class="next-news"
                  :href="singleNews.url_next"
                >
                  <span>Наступна</span>
                </a>
              </div>
            </div>
          </div>
        </article>
        <aside
          v-if="latestNews"
          class="col-12 md:col-4 latest-news__bar hidden lg:block"
        >
          <h1 class="aside__header content__header">Останні новини</h1>
          <LatestNewsBar :latestNews="latestNews" />
          <div class="aside__footer">
            <router-link
              class="all-news__link"
              :to="{
                name: 'all-news',
                params: {
                  group: route.params.group,
                },
              }"
            >
              <span>Дивитись більше</span>
            </router-link>
          </div>
        </aside>
      </div>
    </div>
    <div v-else class="h-screen"></div>
  </main>
</template>

<script setup>
import { onMounted, reactive, ref, computed, watch } from "vue";
import { useRoute } from "vue-router";
import { useHead } from "@unhead/vue";
import { createFilters } from "@/helpers/filters";
import { changeDocumentTitle } from "@/helpers/site";

import PageBreadcrumb from "@/components/public/page/PageBreadcrumb.vue";
import LatestNewsBar from "@/components/public/content/news/LatestNewsBar.vue";
import ShareNewsBtn from "@/components/public/content/news/ShareNewsBtn.vue";

import TYPES from "@/config/types.yml";
import FILTERS from "@/config/filters.yml";

import POSTS_SERVICE from "@/services/public/posts";
import SECTIONS_SERVICE from "@/services/public/sections";

import dateParse from "@/helpers/date";

const route = useRoute();
watch(
  () => route.params,
  async (newParams) => {
    console.log();
    await getNewsPageData(newParams);
  },
  { deep: true }
);

// SECTION
const sections = reactive({});
const sectionFilters = reactive({
  filterBySectionID: {
    field: FILTERS.sections.filterBySectionID,
    value: TYPES.section.news,
  },
});

// NEWS
const latestNews = ref(null);
const newsData = reactive({});

const singleNews = computed(() => {
  if (newsData.rows) {
    return newsData.rows[0];
  }

  return null;
});

const singleDate = computed(() => {
  if (singleNews.value.updated_at) {
    return dateParse.parseLongDate(singleNews.value);
  }

  return null;
});

const singleText = computed(() => {
  if (singleNews.value.text) {
    return route.query.search
      ? POSTS_SERVICE.showSearchedText(
          singleNews.value.text,
          route.query.search
        )
      : singleNews.value.text;
  }

  return null;
});

const newsTag = computed(() => {
  if (singleNews.value.options) {
    let options = JSON.parse(singleNews.value.options);

    return options.tag ? options.tag : null;
  }

  return null;
});

const postFilters = reactive({
  filterByURL: {
    field: FILTERS.posts.filterByURL,
    value: null,
  },
  filterByTypeID: {
    field: FILTERS.posts.filterByTypeID,
    value: TYPES.post.news,
  },
  filterBySectionID: {
    field: FILTERS.posts.filterBySectionID,
    value: TYPES.section.news,
  },
  filterBySubSectionID: {
    field: FILTERS.posts.filterBySubSectionID,
    value: null,
  },
  filterByPublished: {
    field: FILTERS.posts.filterByPublished,
    value: 1,
  },
  sortByDate: {
    field: FILTERS.posts.sortByDate,
    sort: FILTERS.sorting.DESC,
  },
});

async function getNewsPageData(params) {
  let sectionData = await SECTIONS_SERVICE.getSectionsData(
    createFilters({}, [sectionFilters.filterBySectionID]),
    TYPES.post.news
  );

  if (sectionData && params?.url) {
    Object.assign(sections, sectionData[0]);

    let subsectionID = sections.sub_sections.find(
      (subsection) => subsection.url === params.group
    )?.id;

    if (subsectionID) {
      postFilters.filterBySubSectionID.value = subsectionID;
      postFilters.filterByURL.value = params.url;

      let data = await POSTS_SERVICE.getPostsData(
        createFilters({}, [
          postFilters.filterByPublished,
          postFilters.filterByURL,
          postFilters.filterByTypeID,
          postFilters.filterBySectionID,
          postFilters.filterBySubSectionID,
        ]),
        TYPES.post.page
      );

      Object.assign(newsData, data);

      latestNews.value = await POSTS_SERVICE.getPostsData(
        createFilters({ pageSize: 10 }, [
          postFilters.filterByPublished,
          postFilters.filterByTypeID,
          postFilters.filterBySectionID,
          postFilters.filterBySubSectionID,
          postFilters.sortByDate,
        ])
      );

      changeDocumentTitle(singleNews.value.title);
    }
  }
}

onMounted(async () => {
  try {
    await getNewsPageData(route.params, TYPES.post.page);
  } catch (error) {
    console.error(error);
  }
});

// BREADCRUMB
const lastBreadcrumb = computed(() => {
  if (sections.sub_sections && singleNews.value?.id) {
    let subSection = sections.sub_sections.find(
      (el) => el.url === route.params.group
    );

    return [
      { label: sections.name, to: sections.url },
      {
        label: subSection.title,
        to: sections.url + "/" + subSection.url,
      },
      { label: singleNews.value?.title },
    ];
  }

  return {};
});

useHead({
  meta: [
    {
      propherty: "og:title",
      content: () => {
        return singleNews.value ? singleNews.value.title : "";
      },
    },
    {
      propherty: "og:url",
      content: () => {
        return singleNews.value ? window.location.origin + route.fullPath : "";
      },
    },
    {
      propherty: "og:img",
      content: () => {
        return singleNews.value
          ? window.location.origin + singleNews.value.img
          : "";
      },
    },
  ],
});
</script>
