<template>
  <PageBreadcrumb />

  <main class="contacts-page">
    <div class="p-container">
      <section class="section">
        <h1 class="section__header content__header">Контакти</h1>
        <div class="grid">
          <div v-if="postData.rows" class="col-12 lg:col-5 contacts__list">
            <h2>Управління охорони здоров`я Черкаської ОДА</h2>

            <ul>
              <li
                v-for="contact in postData.rows"
                class="contact__item"
                :key="contact"
              >
                <span :class="contact.id_custom">{{ contact.title }}</span>
                <a
                  v-if="
                    contact.id_custom === PHONE_CUSTOM_TYPE ||
                    contact.id_custom === EMAIL_CUSTOM_TYPE
                  "
                  :href="
                    (contact.id_custom === PHONE_CUSTOM_TYPE
                      ? 'tel:'
                      : 'mailto:') + contact.short_text
                  "
                >
                  {{ contact.short_text }}
                </a>
                <p
                  v-else
                  v-html="contact.short_text.replaceAll('\n', '<br />')"
                ></p>
              </li>
            </ul>

            <router-link class="feedback-btn" :to="{ name: 'feedback' }">
              Відправити звернення
            </router-link>
          </div>
          <iframe
            class="col-12 lg:col-7"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d771.2305400899756!2d32.059867422877154!3d49.44482721578632!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40d14b7d86c61657%3A0xd4e53695006908a3!2z0KfQtdGA0LrQsNGB0YzQutCwINC-0LHQu9Cw0YHQvdCwINC00LXRgNC20LDQstC90LAg0LDQtNC80ZbQvdGW0YHRgtGA0LDRhtGW0Y8!5e0!3m2!1suk!2sua!4v1695296257034!5m2!1suk!2sua"
            width="100%"
            height="550px"
            style="border: 0"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </section>
    </div>
  </main>
</template>

<script setup>
import { onMounted, reactive } from "vue";

import { createFilters } from "@/helpers/filters";
import { changeDocumentTitle } from "@/helpers/site";

import PageBreadcrumb from "@/components/public/page/PageBreadcrumb.vue";

import TYPES from "@/config/types.yml";
import FILTERS from "@/config/filters.yml";

import POSTS_SERVICE from "@/services/public/posts";

const PHONE_CUSTOM_TYPE = "phone";
const EMAIL_CUSTOM_TYPE = "email";

// POST
const postData = reactive({});
const postFilters = reactive({
  filterByTypeID: {
    field: FILTERS.posts.filterByTypeID,
    value: TYPES.post.contact,
  },
  filterBySectionID: {
    field: FILTERS.posts.filterBySectionID,
    value: TYPES.section.contact,
  },
  filterByPublished: {
    field: FILTERS.posts.filterByPublished,
    value: 1,
  },
  sortByPosition: {
    field: FILTERS.posts.sortByPosition,
    sort: FILTERS.sorting.ASC,
  },
  filters: {
    pageSize: -1,
  },
});

onMounted(async () => {
  try {
    Object.assign(
      postData,
      await POSTS_SERVICE.getPostsData(
        createFilters(postFilters.filters, [
          postFilters.filterByPublished,
          postFilters.filterBySectionID,
          postFilters.filterByTypeID,
          postFilters.sortByPosition,
        ])
      )
    );

    changeDocumentTitle("Контакти");
  } catch (error) {
    console.error(error);
  }
});
</script>
