import api from "@/api/admin/index";
import editorConfig from "@/config/editor.yml";

import { showUserNotify } from "@/helpers/notify";
import { useAppStore } from "@/store/app";

function createFormData(payload) {
  let formData = new FormData();

  for (let key of Object.keys(payload)) {
    key === "data"
      ? formData.append(key, JSON.stringify(payload[key]))
      : formData.append(key, payload[key]);
  }

  return formData;
}

export default {
  async getPostsData(payload) {
    const appStore = useAppStore();

    appStore.loading = true;

    try {
      let data = (await api.posts.getPostsData(payload)).data;

      appStore.loading = false;

      return data;
    } catch (error) {
      console.error(error);
    }
  },
  async getPostsTypes(payload) {
    try {
      let postTypes = (await api.posts.getPostsTypes(payload)).data;

      return postTypes;
    } catch (error) {
      console.error(error);
    }
  },
  async createPostData(postData) {
    try {
      await api.posts.createPost(createFormData(postData));

      showUserNotify("success");
    } catch (error) {
      throw error;
    }
  },
  async updatePostData(id, postData) {
    try {
      await api.posts.updatePost(id, createFormData(postData));

      showUserNotify("success");
    } catch (error) {
      throw error;
    }
  },
  async removePostData(id) {
    try {
      await api.posts.removePost(id);

      showUserNotify("success");
    } catch (error) {
      throw error;
    }
  },
  async removeIMG(img) {
    try {
      await api.posts.removeIMG(img);
    } catch (error) {
      throw error;
    }
  },
  createFullTextEditorConfig() {
    let config = editorConfig.full_editor_config;
    config.plugins.upload = {
      serverPath: `${process.env.VUE_APP_API_URL}api/administration/pasteimg`,
      fileFieldName: "embedded_img",
      headers: {
        Authorization: `Bearer ${this.adminToken}`,
      },
      urlPropertyName: "img",
      statusPropertyName: "img",
    };

    return config;
  },
};
