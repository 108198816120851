<template>
  <PageBreadcrumb />

  <main v-if="singlePage" class="feedback-page">
    <div class="p-container">
      <div class="grid">
        <section class="col-12 lg:col-7 section">
          <h1 class="section__header content__header">Електронне звернення</h1>
          <FeedbackForm />
        </section>
        <aside class="col-12 lg:col-5">
          <h1 class="aside__header content__header">Вимоги</h1>
          <div class="post__content" v-html="singleText"></div>
        </aside>
      </div>
    </div>
  </main>
</template>

<script setup>
import { computed, reactive, onMounted } from "vue";
import { useRoute } from "vue-router";

import { createFilters } from "@/helpers/filters";
import { changeDocumentTitle } from "@/helpers/site";

import PageBreadcrumb from "@/components/public/page/PageBreadcrumb.vue";
import FeedbackForm from "@/components/public/content/feedback/FeedbackForm.vue";

import TYPES from "@/config/types.yml";
import FILTERS from "@/config/filters.yml";

import POSTS_SERVICE from "@/services/public/posts";

const route = useRoute();

// POST
const postData = reactive({});
const singlePage = computed(() => {
  if (postData.rows) {
    return postData.rows[0];
  }

  return null;
});

const singleText = computed(() => {
  if (singlePage.value.text) {
    return route.query.search
      ? POSTS_SERVICE.showSearchedText(
          singlePage.value.text,
          route.query.search
        )
      : singlePage.value.text;
  }

  return null;
});

const postFilters = reactive({
  filterByURL: {
    field: FILTERS.posts.filterByURL,
    value: "feedback",
  },
  filterByTypeID: {
    field: FILTERS.posts.filterByTypeID,
    value: TYPES.post.feedback,
  },
  filterBySectionID: {
    field: FILTERS.posts.filterBySectionID,
    value: TYPES.section.contact,
  },
  filterByPublished: {
    field: FILTERS.posts.filterByPublished,
    value: 1,
  },
});

async function getPost() {
  let data = await POSTS_SERVICE.getPostsData(
    createFilters({}, [
      postFilters.filterByPublished,
      postFilters.filterByURL,
      postFilters.filterByTypeID,
      postFilters.filterBySectionID,
    ]),
    TYPES.post.page
  );

  Object.assign(postData, data);
}

onMounted(async () => {
  try {
    await getPost();
    changeDocumentTitle("Електронне звернення");
  } catch (error) {
    console.error(error);
  }
});
</script>
