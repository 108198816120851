<template>
  <div class="news-page__share">
    <a
      target="_blank"
      class="fb-xfbml-parse-ignore"
      :href="`https://www.facebook.com/sharer/sharer.php?u=${url}`"
    >
      <span>Поділитися</span>
    </a>
  </div>
</template>

<script setup>
import { defineProps, computed } from "vue";
import { useRoute } from "vue-router";

const route = useRoute();

const props = defineProps({
  title: String,
});
const url = computed(() => window.location.origin + route.fullPath);
</script>
