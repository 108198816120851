<template>
  <div class="flex filter-field gap-1 justify-content-center">
    <Dropdown
      v-if="filter && showOptions && Array.isArray(filterItems)"
      v-model="filterValue"
      optionValue="id"
      class="w-full"
      :optionLabel="labelOption"
      :placeholder="placeholder"
      :options="filterItems"
      @change="filtrate('value')"
    />
    <div v-else-if="filter && showOptions" class="w-full">
      <form
        action=""
        class="flex justify-content-between"
        @submit.prevent="emits('applyFilters')"
      >
        <InputText
          type="text"
          v-model="filterValue"
          :placeholder="placeholder"
          :class="['filter-search-field', showSearchBtn ? 'w-10' : 'w-full']"
          @input="changeFilterField"
        />
        <Button
          v-show="showSearchBtn"
          type="submit"
          class="p-button-text"
          icon="pi pi-search"
          v-tooltip.bottom="`Пошук...`"
        />
      </form>
    </div>
    <Button
      v-show="filterValue !== null || !showOptions"
      class="p-button-text"
      icon="pi pi-filter-slash"
      v-tooltip.bottom="`Скинути ${showOptions ? '' : 'всі '}фільтри`"
      @click="showOptions ? clearFilter() : emits('clearFilters')"
    />

    <Button
      v-show="showOptions && sorting"
      class="p-button-text"
      :icon="`pi ${
        sortedValue === ASC_SORTING
          ? 'pi-sort-amount-down-alt'
          : 'pi-sort-amount-down'
      }`"
      v-tooltip.bottom="`Сортувати`"
      @click="filtrate('sort')"
    />
  </div>
</template>

<script setup>
import { defineProps, defineEmits, ref, onMounted } from "vue";

import Dropdown from "primevue/dropdown";
import Button from "primevue/button";
import InputText from "primevue/inputtext";

const ASC_SORTING = "ASC";
const DESC_SORTING = "DESC";

const props = defineProps({
  filter: Object,
  sorting: Object,
  filterItems: [Array, String],
  placeholder: String,
  filterType: String,
  filtersName: String,
  labelOption: {
    type: String,
    default: "name",
  },
  showOptions: {
    type: Boolean,
    default() {
      return true;
    },
  },
  showSearchBtn: {
    type: Boolean,
    default() {
      return true;
    },
  },
});
const emits = defineEmits([
  "clearFilters",
  "applyFilters",
  "applySorted",
  "update:filter",
  "update:sorting",
]);

const filterValue = ref(null);
const sortedValue = ref(null);

function filtrate(field) {
  let filter;

  switch (field) {
    case "value":
      filter = { ...props.filter };
      filter.value = filterValue.value;

      emits("update:filter", filter);
      emits("applyFilters");
      break;
    case "sort":
      filter = { ...props.sorting };

      sortedValue.value =
        sortedValue.value === ASC_SORTING ? DESC_SORTING : ASC_SORTING;

      filter.sort = sortedValue.value;

      emits("update:sorting", filter);
      emits("applySorted");
      break;
    default:
      break;
  }
}

function clearFilter() {
  let filter = { ...props.filter };
  filter.value = null;
  filterValue.value = null;

  emits("update:filter", filter);
  emits("applyFilters");
}

function changeFilterField() {
  let filter = { ...props.filter };
  filter.value = filterValue;

  emits("update:filter", filter);
}

onMounted(() => {
  if (props.sorting) {
    sortedValue.value = props.sorting.sort ? props.sorting.sort : ASC_SORTING;
  }

  if (props.filtersName && localStorage.getItem(props.filtersName)) {
    let filters = JSON.parse(localStorage.getItem(props.filtersName));

    if (filters.filtered && filters.filtered.length > 0) {
      if (props.filter) {
        let filterItem = filters.filtered.find(
          (el) => el.field === props.filter.field
        );

        filterValue.value = filterItem ? filterItem.value : null;
      }

      if (props.sorting) {
        let sortItem = filters.filtered.find(
          (el) => el.field === props.sorting.field
        );

        sortedValue.value = sortItem ? sortItem.sort : null;
      }
    }
  }
});
</script>
