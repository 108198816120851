<template>
  <div class="mt-3">
    <form class="pt-2" @submit.prevent="sendPost">
      <div class="p-fluid grid">
        <div class="col-12">
          <span class="p-float-label">
            <InputText
              id="title"
              type="text"
              :class="contact.title ? '' : 'p-invalid'"
              v-model="contact.title"
            />
            <label for="title">Заголовок</label>
          </span>
        </div>
        <div class="col-12 md:col-6 mt-3">
          <span class="p-float-label">
            <InputText
              id="url"
              type="text"
              :class="contact.url ? '' : 'p-invalid'"
              v-model="contact.url"
            />
            <label for="url">Значення</label>
          </span>
        </div>
        <div class="col-12 md:col-6 mt-3">
          <span class="p-float-label">
            <InputText
              id="id_custom"
              type="text"
              :class="contact.id_custom ? '' : 'p-invalid'"
              v-model="contact.id_custom"
            />
            <label for="id_custom">ID</label>
          </span>
        </div>
      </div>
      <Divider type="solid" class="mt-1" />
      <div class="p-fluid grid">
        <div class="col-12 md:col-6">
          <ToggleButton
            v-model="isPublished"
            onLabel="Опублікувати"
            offLabel="Як чернетка"
          />
        </div>
      </div>
      <div class="form-footer text-right">
        <Button
          autofocus
          icon="pi pi-times"
          label="Закрити"
          @click="dialog.close()"
        />
        <Button
          type="submit"
          icon="pi pi-check"
          class="ml-2 p-button-warning"
          label="Зберегти"
          :disabled="
            !contact.title || !contact.short_text || !contact.id_custom
          "
        />
      </div>
    </form>
  </div>
</template>

<script setup>
import { defineEmits, onMounted, ref, reactive, inject } from "vue";
import { useRoute } from "vue-router";

import InputText from "primevue/inputtext";
import Divider from "primevue/divider";
import Button from "primevue/button";
import ToggleButton from "primevue/togglebutton";

const dialog = inject("dialogRef");

const emits = defineEmits(["update"]);

const route = useRoute();

// СONTACT
const contact = reactive({
  title: null,
  url: null,
  id_custom: null,
  id_type: null,
  id_subsection: null,
  id_section: null,
  is_published: null,
});
const isPublished = ref(false);

onMounted(async () => {
  let dialogData = dialog.value.data;

  if (dialogData.post?.id) {
    Object.assign(contact, dialogData.post);

    isPublished.value = !!contact.is_published;
  }
});

function sendPost() {
  contact.is_published = isPublished.value ? 1 : 0;
  contact.id_section = route.meta.section;
  contact.id_type = route.meta.type;

  let postData = {
    data: contact,
    img: null,
  };

  emits("update", {
    post: postData,
    dialog: dialog.value,
  });
}
</script>
