import app from "../main";
import config from "../config/config.yml";

const NOTIFY_MESSAGE_DEFAULT = {
  success: "Зміни збережено",
  error: "Помилка запиту",
};

function createNotify(type, message, delay) {
  app.config.globalProperties.$toast.add({
    severity: type,
    detail: message ? message : NOTIFY_MESSAGE_DEFAULT[type],
    life: delay ? delay : 3000,
  });
}

function showNetworkErrorNotify(error) {
  let code_translate = error.response
    ? config.errors_code[error.response.status]
    : NOTIFY_MESSAGE_DEFAULT["error"];

  createNotify("error", code_translate);
}

function showUserNotify(type, message, delay) {
  createNotify(type, message, delay);
}

export { showNetworkErrorNotify, showUserNotify };
