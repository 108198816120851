<template>
  <div class="page-footer__sitemap">
    <button
      :class="`sitemap__btn ${showSiteMap ? 'active' : ''}`"
      @click="showSiteMap = !showSiteMap"
    >
      <span>Мапа порталу</span>
    </button>
    <div :class="`sitemap__page-list ${showSiteMap ? 'show' : ''}`">
      <div class="grid">
        <div
          class="col-12 md:col-4 lg:col-3 sitemap__item"
          v-for="item in menuItems"
          :key="item.id"
        >
          <router-link
            class="page-title sitemap__link"
            :to="{ path: item.url }"
          >
            {{ item.name }}
          </router-link>
          <ul v-if="item.sub_sections" class="page__subpage-list">
            <li
              v-for="subsection in item.sub_sections"
              class="subpost__title sitemap__link"
              :key="subsection.id"
            >
              <a v-if="!!subsection.url_is_external" :href="subsection.url">
                <span>{{ subsection.title }}</span>
              </a>
              <router-link
                v-else
                class="subpost__title sitemap__link"
                :to="`${item.url}/${subsection.url}`"
              >
                <span>
                  {{ subsection.title }}
                </span>
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, inject } from "vue";

const menuItems = inject("siteMenuItems");
const showSiteMap = ref(false);
</script>
