export default {
  parseShortDate(post) {
    if (post.updated_at) {
      let date = new Date(post.updated_at.replace(/-/g, "/"));

      return (
        date.toLocaleString("uk-UA", {
          day: "numeric",
          month: "numeric",
          year: "numeric",
        }) +
        " | " +
        date.toLocaleTimeString("uk-UA").substring(0, 5)
      );
    }

    return null;
  },
  parseLongDate(post) {
    if (post.updated_at) {
      let date = new Date(post.updated_at.replace(/-/g, "/"));

      return (
        "Опубліковано " +
        date.toLocaleString("uk-UA", {
          day: "numeric",
          month: "long",
          year: "numeric",
        }) +
        " о " +
        date.toLocaleTimeString("uk-UA").substring(0, 5)
      );
    }

    return null;
  },
};
