import { createRouter, createWebHistory } from "vue-router";

import middleware from "./middleware";

import publicPages from "./public";
import adminPages from "./admin";

import PublicView from "../views/public/PublicView.vue";
import PageNotFoundView from "../views/PageNotFoundView.vue";

const routes = [
  {
    path: "/",
    component: PublicView,
    children: publicPages,
  },
  {
    path: "/backend",
    children: adminPages,
  },

  // other
  { path: "/:pathMatch(.*)*", name: "404-page", component: PageNotFoundView },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    return { top: 0, behavior: "smooth" };
  },
});

router.beforeEach((to, from, next) => middleware.getAccessPermission(to, next));

export default router;
