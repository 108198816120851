import instance from "../instance";

import sections from "./sections";
import posts from "./posts";
import search from "./search";
import feedback from "./feedback";

export default {
  sections: sections(instance),
  posts: posts(instance),
  search: search(instance),
  feedback: feedback(instance),
};
