<template>
  <main class="main-page">
    <section v-if="sliders" class="section section__slider">
      <div class="p-container">
        <MainSlider :sliders="sliders" />
      </div>
    </section>
    <div class="p-container">
      <div class="grid">
        <section class="col-12 lg:col-7 section section__latest-news">
          <h2 class="section__header content__header">Новини</h2>
          <LatestNews />
        </section>

        <section class="col-12 lg:col-5 section section__manager">
          <h2 class="section__header content__header">Керівництво</h2>
          <MainManagerCard :manager="leaderManager" />
        </section>
      </div>
    </div>
    <section v-if="usefulLinks" class="section section__useful-links">
      <div class="p-container">
        <h2
          class="section__header content__header flex justify-content-between"
          @click="showLinks = !showLinks"
        >
          Корисні посилання
          <i
            :class="[
              'section__hidden-icon md:hidden',
              showLinks ? 'section__hidden-icon--rotate' : '',
            ]"
          ></i>
        </h2>
        <div :class="['useful-links_list md:block', showLinks ? '' : 'hidden']">
          <div class="grid justify-content-center">
            <div
              v-for="link in usefulLinks"
              :key="link"
              class="col-12 md:col-4"
            >
              <LinkCard :link="link" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section section__map">
      <div class="p-container">
        <h2
          class="section__header content__header flex justify-content-between"
          @click="showMap = !showMap"
        >
          Розташування
          <i
            :class="[
              'section__hidden-icon md:hidden',
              showMap ? 'section__hidden-icon--rotate' : '',
            ]"
          ></i>
        </h2>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d771.2305400899756!2d32.059867422877154!3d49.44482721578632!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40d14b7d86c61657%3A0xd4e53695006908a3!2z0KfQtdGA0LrQsNGB0YzQutCwINC-0LHQu9Cw0YHQvdCwINC00LXRgNC20LDQstC90LAg0LDQtNC80ZbQvdGW0YHRgtGA0LDRhtGW0Y8!5e0!3m2!1suk!2sua!4v1695296257034!5m2!1suk!2sua"
          width="100%"
          height="430"
          style="border: 0"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
          :class="['md:block', showMap ? '' : 'hidden']"
        ></iframe>
      </div>
    </section>
  </main>
</template>

<script setup>
import { ref, reactive, onMounted } from "vue";
import { createFilters } from "@/helpers/filters";
import { changeDocumentTitle } from "@/helpers/site";

import MainSlider from "@/components/public/content/slider/MainSlider.vue";
import LatestNews from "@/components/public/content/news/LatestNews.vue";
import MainManagerCard from "@/components/public/content/manager/MainManagerCard.vue";
import LinkCard from "@/components/public/content/useful-links/LinkCard.vue";

import TYPES from "@/config/types.yml";
import FILTERS from "@/config/filters.yml";

import POSTS_SERVICE from "@/services/public/posts";

const LEADER_CUSTOM_ID = "leader";

const showMap = ref(false);
const showLinks = ref(false);

//POSTS
const postFilters = reactive({
  filterByTypeID: {
    field: FILTERS.posts.filterByTypeID,
    value: null,
  },
  filterBySectionID: {
    field: FILTERS.posts.filterBySectionID,
    value: null,
  },
  filterBySubSectionID: {
    field: FILTERS.posts.filterBySubSectionID,
    value: null,
  },
  filterByPublished: {
    field: FILTERS.posts.filterByPublished,
    value: 1,
  },
  sortByPosition: {
    field: FILTERS.posts.sortByPosition,
    sort: null,
  },
  sortByDate: {
    field: FILTERS.posts.sortByDate,
    sort: FILTERS.sorting.DESC,
  },
});

async function getPosts(type) {
  let defaultFilter = {
    pageSize: -1,
  };

  postFilters.filterByTypeID.value = type;

  let filtered = [postFilters.filterByPublished, postFilters.filterByTypeID];
  if (type === TYPES.post.slider) {
    filtered.push(postFilters.sortByDate);
  }

  return (
    await POSTS_SERVICE.getPostsData(createFilters(defaultFilter, filtered))
  ).rows;
}

// SLIDERS
const sliders = ref(null);
onMounted(async () => {
  try {
    sliders.value = await getPosts(TYPES.post.slider);
  } catch (error) {
    console.error(error);
  }
});

// USEFUL LINKS
const usefulLinks = ref(null);
onMounted(async () => {
  try {
    usefulLinks.value = await getPosts(TYPES.post.link);
  } catch (error) {
    console.error(error);
  }
});

// MANAGERS
const leaderManager = reactive({});
onMounted(async () => {
  try {
    let managers = await getPosts(TYPES.post.manager);
    if (managers) {
      Object.assign(
        leaderManager,
        managers.find((el) => el.id_custom === LEADER_CUSTOM_ID)
      );
    }
  } catch (error) {
    console.error(error);
  }
});

onMounted(() => {
  changeDocumentTitle(process.env.VUE_APP_SITE_NAME);
});
</script>
