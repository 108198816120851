import api from "@/api/public/index";
import router from "@/router";

import TYPES from "@/config/types.yml";

const PAGE_POST_TYPE = TYPES.post.page;
const NEWS_POST_TYPE = TYPES.post.news;
const FEEDBACK_POST_TYPE = TYPES.post.news;

export default {
  async getPostsData(payload, type) {
    try {
      let data = (await api.posts.getPostsData(payload)).data;

      if (
        (type === PAGE_POST_TYPE ||
          type === NEWS_POST_TYPE ||
          type === FEEDBACK_POST_TYPE) &&
        !data.rows
      ) {
        router.push({ name: "404-page" });
      }

      return data;
    } catch (error) {
      throw error;
    }
  },
  createURL(post) {
    if (post.url_is_external) {
      return post.url;
    }

    let url = post.post_section.url;

    if (post.post_subsection.url) {
      url += "/" + post.post_subsection.url;
    }

    return url + "/" + post.url;
  },
  showSearchedText(text, searchQuery) {
    let searchWords = searchQuery.trim().split(" ");

    return searchWords.reduce((temp, word) => {
      if (word && word.length > 2) {
        try {
          let regex = new RegExp(word, "gi");

          return (temp = temp.replace(
            regex,
            `<span class="result__word">${
              temp.match(regex) ? temp.match(regex)[0] : ""
            }</span>`
          ));
        } catch {
          return temp;
        }
      } else {
        return temp;
      }
    }, text);
  },
};
