<template>
  <PageBreadcrumb v-if="singlePage" :lastBreadcrumb="lastBreadcrumb" />

  <main v-if="singlePage" class="single-page">
    <div class="p-container">
      <article>
        <h1 class="section__header content__header hidden lg:block">
          {{ section.name }}
        </h1>
        <h1 class="single-page__title post__title">
          {{ singlePage.title }}
        </h1>
        <div
          class="single-post__content post__content"
          v-html="singleText"
        ></div>
      </article>
    </div>
  </main>
  <div v-else class="h-screen"></div>
</template>

<script setup>
import { computed, watch, reactive, onMounted } from "vue";

import { useRoute } from "vue-router";

import { createFilters } from "@/helpers/filters";
import { changeDocumentTitle } from "@/helpers/site";

import PageBreadcrumb from "@/components/public/page/PageBreadcrumb.vue";

import TYPES from "@/config/types.yml";
import FILTERS from "@/config/filters.yml";

import POSTS_SERVICE from "@/services/public/posts";
import SECTIONS_SERVICE from "@/services/public/sections";

const route = useRoute();
watch(
  () => route.params,
  async (newParams) => {
    if (newParams.section && newParams.page) {
      await getPost(newParams);
    }
  },
  { deep: true }
);

// SECTION
const section = reactive({});
const sectionFilters = reactive({
  filterBySectionURL: {
    field: FILTERS.sections.filterBySectionURL,
    value: null,
  },
});

// POST
const postData = reactive({});
const singlePage = computed(() => {
  if (postData.rows) {
    return postData.rows[0];
  }

  return null;
});
const singleText = computed(() => {
  if (singlePage.value.text) {
    return route.query.search
      ? POSTS_SERVICE.showSearchedText(
          singlePage.value.text,
          route.query.search
        )
      : singlePage.value.text;
  }

  return null;
});

const postFilters = reactive({
  filterByURL: {
    field: FILTERS.posts.filterByURL,
    value: null,
  },
  filterByTypeID: {
    field: FILTERS.posts.filterByTypeID,
    value: TYPES.post.page,
  },
  filterBySectionID: {
    field: FILTERS.posts.filterBySectionID,
    value: null,
  },
  filterByPublished: {
    field: FILTERS.posts.filterByPublished,
    value: 1,
  },
});

async function getPost(params) {
  sectionFilters.filterBySectionURL.value = "/" + params.section;

  let sectionData = await SECTIONS_SERVICE.getSectionsData(
    createFilters({}, [sectionFilters.filterBySectionURL])
  );

  Object.assign(section, sectionData?.[0]);

  postFilters.filterBySectionID.value = section.id;
  postFilters.filterByURL.value = params.page;

  let data = await POSTS_SERVICE.getPostsData(
    createFilters({}, [
      postFilters.filterByPublished,
      postFilters.filterByURL,
      postFilters.filterByTypeID,
      postFilters.filterBySectionID,
    ]),
    TYPES.post.page
  );

  Object.assign(postData, data);

  if (singlePage.value) {
    changeDocumentTitle(singlePage.value.title);
  }
}

onMounted(async () => {
  try {
    await getPost(route.params, TYPES.post.page);
  } catch (error) {
    console.error(error);
  }
});

// BREADCRUMB
const lastBreadcrumb = computed(() => {
  if (section && singlePage.value?.id) {
    return [
      { label: section.name, to: section.url },
      { label: singlePage.value?.title },
    ];
  }
  return {};
});
</script>
