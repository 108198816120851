import api from "@/api/public/index";
import router from "@/router";

export default {
  async getSectionsData(payload) {
    try {
      let data = (await api.sections.getSections(payload)).data.data;

      if (!data) {
        router.push({ name: "404-page" });
      }

      return data;
    } catch (error) {
      throw error;
    }
  },
};
