import api from "@/api/admin/index";

export default {
  async getSectionsData(payload) {
    try {
      let data = (await api.sections.getSections(payload)).data;

      return data;
    } catch (error) {
      console.error(error);
    }
  },
};
