<template>
  <div class="mt-3">
    <form class="pt-2" @submit.prevent="sendPost">
      <div class="grid">
        <div class="col-12 md:col-8 p-fluid grid">
          <div class="col-12">
            <span class="p-float-label">
              <InputText
                id="fullname"
                type="text"
                :class="manager.title ? '' : 'p-invalid'"
                v-model="manager.title"
              />
              <label for="fullname">П. І. Б</label>
            </span>
          </div>
          <div class="col-12 md:col-6 mt-2">
            <span class="p-float-label">
              <Textarea
                rows="3"
                id="manager_post"
                type="text"
                class="w-full"
                v-model="manager.short_text"
              />
              <label for="manager_post">Посада</label>
            </span>
          </div>
          <div class="col-12 md:col-6 mt-2">
            <span class="p-float-label">
              <Textarea
                rows="3"
                id="manager_schedule"
                type="text"
                class="w-full"
                v-model="manager.text"
              />
              <label for="manager_schedule">Приймальні дні</label>
            </span>
          </div>
          <div class="col-12 mt-2">
            <Dropdown
              optionLabel="label"
              optionValue="value"
              placeholder="Оберіть тип посади"
              v-model="manager.id_custom"
              :class="manager.id_custom ? '' : 'p-invalid'"
              :options="types"
            />
          </div>
          <div v-if="manager.id_custom === LEADER_TYPE" class="col-12">
            <ManagerContactForm v-model:contacts="manager.options.contacts" />
          </div>
        </div>
        <div class="col-12 md:col-4">
          <UploadImgPanel
            :title="'Фото співробітника'"
            :id="'manager_img'"
            :img="mainIMG"
            :imgSrc="manager.img"
            @update:img="mainIMG = $event"
            @update:imgSrc="manager.img = $event"
          />
        </div>
      </div>
      <Divider type="solid" class="mt-1" />
      <div class="p-fluid grid">
        <div class="col-12 md:col-6">
          <ToggleButton
            v-model="isPublished"
            onLabel="Опублікувати"
            offLabel="Як чернетка"
          />
        </div>
      </div>
      <div class="form-footer text-right">
        <Button
          autofocus
          icon="pi pi-times"
          label="Закрити"
          @click="dialog.close()"
        />
        <Button
          type="submit"
          icon="pi pi-check"
          class="ml-2 p-button-warning"
          label="Зберегти"
          :disabled="!manager.title || !manager.id_custom"
        />
      </div>
    </form>
  </div>
</template>

<script setup>
import { defineEmits, onMounted, ref, reactive, inject } from "vue";
import { useRoute } from "vue-router";
import { showUserNotify } from "@/helpers/notify";

import InputText from "primevue/inputtext";
import Divider from "primevue/divider";
import Dropdown from "primevue/dropdown";
import Button from "primevue/button";
import ToggleButton from "primevue/togglebutton";
import Textarea from "primevue/textarea";
import UploadImgPanel from "../editor/UploadImgPanel.vue";
import ManagerContactForm from "./ManagerContactForm.vue";

const dialog = inject("dialogRef");

const emits = defineEmits(["update"]);

const route = useRoute();

// MANAGER
const manager = reactive({
  id_custom: null,
  title: null,
  short_text: null,
  img: null,
  id_type: null,
  id_subsection: null,
  id_section: null,
  is_published: null,
  options: null,
});
const isPublished = ref(false);
const mainIMG = ref(null);

const LEADER_TYPE = "leader";
const types = [
  {
    id: 1,
    value: LEADER_TYPE,
    label: "Керівник",
  },
  {
    id: 1,
    value: "manager",
    label: "Заступник",
  },
  {
    id: 1,
    value: "other",
    label: "Інше",
  },
];

function sendPost() {
  manager.is_published = isPublished.value ? 1 : 0;
  manager.id_section = route.meta.section;
  manager.id_type = route.meta.type;
  manager.options = JSON.stringify(manager.options);

  let postData = {
    data: manager,
    img: mainIMG.value,
  };

  emits("update", {
    post: postData,
    dialog: dialog.value,
  });
}

function parseContacts() {
  try {
    let options = JSON.parse(manager.options);

    if (!options || typeof options !== "object") {
      options = {};
    }

    if (!options.contacts) {
      options.contacts = [];
    }

    manager.options = options;
  } catch (error) {
    showUserNotify("error", error);
  }
}

onMounted(async () => {
  let dialogData = dialog.value.data;

  if (dialogData.post?.id) {
    Object.assign(manager, dialogData.post);

    isPublished.value = !!manager.is_published;
  }

  parseContacts();
});
</script>
