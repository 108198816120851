<template>
  <div class="navbar">
    <div class="p-container">
      <Menubar :model="menuItems">
        <template #end>
          <Button
            class="signout-btn"
            :label="AUTH_SERVICE.userFullName()"
            @click="toggle"
          />
          <Menu
            ref="menu"
            id="overlay_menu"
            :model="userMenuItems"
            :popup="true"
          />
        </template>
      </Menubar>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";

import Button from "primevue/button";
import Menubar from "primevue/menubar";
import Menu from "primevue/menu";

import AUTH_SERVICE from "@/services/admin/auth";

const menu = ref();
const toggle = (event) => {
  menu.value.toggle(event);
};

const userMenuItems = ref([
  {
    label: "Вихід",
    icon: "pi pi-sign-out",
    command: async () => await AUTH_SERVICE.signOutAdmin(),
  },
]);

const menuItems = ref([
  {
    label: "Новини",
    url: "/backend/workspace/news",
  },
  {
    label: "Керівництво",
    url: "/backend/workspace/managers",
  },
  {
    label: "Публікації",
    items: [
      {
        label: "Слайдер",
        url: "/backend/workspace/site/sliders",
      },
      {
        label: "Про Управління",
        url: "/backend/workspace/about",
      },
      {
        label: "Діяльність",
        url: "/backend/workspace/activity",
      },
      {
        label: "Публічна інформація",
        url: "/backend/workspace/public",
      },
    ],
  },
  {
    label: "Контакти",
    items: [
      {
        label: "Контакти управління",
        url: "/backend/workspace/contacts",
      },
      {
        label: "Електронне звернення",
        url: "/backend/workspace/feedback",
      },
    ],
  },
  {
    label: "Сайт",
    items: [
      {
        label: "Корисні посилання",
        url: "/backend/workspace/site/links",
      },
      {
        label: "Контакти",
        url: "/backend/workspace/site/contacts",
      },
    ],
  },
  {
    label: "Користувачі",
    url: "/backend/workspace/users",
    visible: () => AUTH_SERVICE.isAdmin(),
  },
]);
</script>
