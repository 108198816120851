<template>
  <div class="managers__item manager">
    <img v-if="manager.img" class="manager__photo" :src="manager.img" alt="" />
    <div>
      <h2 class="manager__fullname">{{ manager.title }}</h2>
      <span class="manager__post"> {{ manager.short_text }} </span>
      <div class="schedule manager__schedule">
        <button class="schedule__show-btn" type="button" @click="toggle">
          <span>Приймальні дні</span>
        </button>

        <OverlayPanel class="schedule__list" ref="op">
          <div class="" v-html="manager.text.replaceAll('\n', '<br />')"></div>
        </OverlayPanel>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, defineProps } from "vue";

import OverlayPanel from "primevue/overlaypanel";
import Button from "primevue/button";

const props = defineProps({
  manager: Object,
});

const op = ref();
const toggle = (event) => {
  op.value.toggle(event);
};
</script>
