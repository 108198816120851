<template>
  <div class="latest-news__bar">
    <ul>
      <li v-for="news in latestNews.rows" :key="news.id">
        <div class="flex justify-content-between align-items-center">
          <span class="post__date">{{ dateParse.parseShortDate(news) }}</span>
          <span v-if="getNewsTag(news)" class="news-tag">
            {{ getNewsTag(news) }}
          </span>
        </div>

        <PageLink
          :isExternal="news.url_is_external"
          :url="POSTS_SERVICE.createURL(news)"
        >
          {{ news.title }}
        </PageLink>
      </li>
    </ul>
  </div>
</template>

<script setup>
import { defineProps } from "vue";

import PageLink from "@/components/public/page/PageLink.vue";

import POSTS_SERVICE from "@/services/public/posts";

import dateParse from "@/helpers/date";

const props = defineProps({
  latestNews: Object,
});

function getNewsTag(news) {
  if (news.options) {
    let options = JSON.parse(news.options);
    return options.tag ? options.tag : null;
  }

  return null;
}
</script>
