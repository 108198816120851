import api from "@/api/admin/index";
import axios from "@/api/instance";
import jwt_decode from "jwt-decode";
import router from "@/router";
import { checkRelevanceToken } from "@/helpers/token";

const TOKEN = process.env.VUE_APP_TOKEN_NAME;
const ADMIN_ROLE = 1;

export default {
  async signIn(user) {
    try {
      let token = (await api.auth.signIn(user)).data.token;

      localStorage.setItem(TOKEN, token);

      axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    } catch (error) {
      throw error;
    }
  },
  async signOutAdmin() {
    try {
      if (localStorage.getItem(TOKEN)) {
        localStorage.removeItem(TOKEN);
      }
      await api.auth.signOut();
      delete axios.defaults.headers.common.Authorization;

      router.push("/");
    } catch (error) {
      throw error;
    }
  },
  adminTokenIsValid() {
    if (localStorage.getItem(TOKEN)) {
      return checkRelevanceToken(localStorage.getItem(TOKEN));
    }

    return false;
  },
  isAdmin() {
    if (localStorage.getItem(TOKEN)) {
      return jwt_decode(localStorage.getItem(TOKEN)).type === ADMIN_ROLE;
    }

    return false;
  },
  userFullName() {
    if (localStorage.getItem(TOKEN)) {
      return jwt_decode(localStorage.getItem(TOKEN)).user;
    }

    return false;
  },
};
