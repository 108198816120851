<template>
  <div class="mt-3">
    <form class="pt-2" @submit.prevent="sendPost">
      <div class="grid">
        <div class="col-12 md:col-8 p-fluid grid">
          <div class="col-12 mb-2">
            <span class="p-float-label">
              <InputText
                id="title"
                type="text"
                v-model="slider.title"
                :class="slider.title ? '' : 'p-invalid'"
              />
              <label for="title">Заголовок</label>
            </span>
          </div>
          <div class="col-12 mb-2">
            <span class="p-float-label">
              <InputText
                id="url"
                type="text"
                v-model="slider.url"
                :class="slider.url ? '' : 'p-invalid'"
              />
              <label for="url">Посилання</label>
            </span>
            <div class="flex align-items-center mt-2">
              <Checkbox
                binary
                v-model="isExternalLink"
                inputId="isExternalLink"
              />
              <label for="isExternalLink" class="ml-2">
                Посилання на сторонній ресурс
              </label>
            </div>
          </div>
          <div class="col-12">
            <span class="p-float-label">
              <Textarea
                rows="5"
                id="short_text"
                type="text"
                class="w-full"
                v-model="slider.short_text"
              />
              <label for="short_text">Опис</label>
            </span>
          </div>
        </div>
        <div class="col-12 md:col-4 flex flex-column justify-content-center">
          <UploadImgPanel
            :title="'Зображення слайду'"
            :id="'slider_img'"
            :img="mainIMG"
            :imgSrc="slider.img"
            @update:img="mainIMG = $event"
            @update:imgSrc="slider.img = $event"
          />
        </div>
      </div>
      <Divider type="solid" class="mt-1" />
      <div class="p-fluid grid">
        <div class="col-12 md:col-6">
          <ToggleButton
            v-model="isPublished"
            onLabel="Опублікувати"
            offLabel="Як чернетка"
          />
        </div>
      </div>
      <div class="form-footer text-right">
        <Button
          autofocus
          icon="pi pi-times"
          label="Закрити"
          @click="dialog.close()"
        />
        <Button
          type="submit"
          icon="pi pi-check"
          class="ml-2 p-button-warning"
          label="Зберегти"
          :disabled="!slider.title || !slider.url"
        />
      </div>
    </form>
  </div>
</template>

<script setup>
import { defineEmits, onMounted, ref, reactive, inject } from "vue";
import { useRoute } from "vue-router";

import InputText from "primevue/inputtext";
import Divider from "primevue/divider";
import Button from "primevue/button";
import ToggleButton from "primevue/togglebutton";
import Textarea from "primevue/textarea";
import UploadImgPanel from "../editor/UploadImgPanel.vue";
import Checkbox from "primevue/checkbox";

const dialog = inject("dialogRef");

const emits = defineEmits(["update"]);

const route = useRoute();

// MANAGER
const slider = reactive({
  url: null,
  url_is_external: 0,
  title: null,
  short_text: null,
  img: null,
  id_type: null,
  id_subsection: null,
  id_section: null,
  is_published: null,
});
const isExternalLink = ref(false);
const isPublished = ref(false);
const mainIMG = ref(null);

onMounted(async () => {
  let dialogData = dialog.value.data;

  if (dialogData.post?.id) {
    Object.assign(slider, dialogData.post);

    isPublished.value = !!slider.is_published;
    isExternalLink.value = !!slider.url_is_external;
  }
});

function sendPost() {
  slider.is_published = isPublished.value ? 1 : 0;
  slider.url_is_external = isExternalLink.value ? 1 : 0;

  slider.id_section = route.meta.section;
  slider.id_type = route.meta.type;

  let postData = {
    data: slider,
    img: mainIMG.value,
  };

  emits("update", {
    post: postData,
    dialog: dialog.value,
  });
}
</script>
