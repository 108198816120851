<template>
  <PageBreadcrumb />

  <main class="information-page">
    <div class="p-container">
      <section class="section">
        <h1 class="section__header content__header">Публічна інформація</h1>
        <TabView v-if="postData.rows" class="information-tab" scrollable>
          <TabPanel
            v-for="post in postData.rows"
            :key="post.id"
            :header="post.title"
          >
            <div
              class="information-post__content post__content"
              v-html="showSearchWords(post.text)"
            ></div>
          </TabPanel>
        </TabView>
      </section>
    </div>
  </main>
</template>

<script setup>
import { reactive, onMounted } from "vue";
import { useRoute } from "vue-router";

import { createFilters } from "@/helpers/filters";
import { changeDocumentTitle } from "@/helpers/site";

import PageBreadcrumb from "@/components/public/page/PageBreadcrumb.vue";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";

import TYPES from "@/config/types.yml";
import FILTERS from "@/config/filters.yml";

import POSTS_SERVICE from "@/services/public/posts";

const route = useRoute();

// POST
const postData = reactive({});

const postFilters = reactive({
  filterByTypeID: {
    field: FILTERS.posts.filterByTypeID,
    value: TYPES.post.page,
  },
  filterBySectionID: {
    field: FILTERS.posts.filterBySectionID,
    value: TYPES.section.public,
  },
  filterByPublished: {
    field: FILTERS.posts.filterByPublished,
    value: 1,
  },
  sortByDate: {
    field: FILTERS.posts.sortByDate,
    sort: FILTERS.sorting.DESC,
  },
});

async function getPost() {
  let data = await POSTS_SERVICE.getPostsData(
    createFilters({}, [
      postFilters.filterByPublished,
      postFilters.filterByTypeID,
      postFilters.filterBySectionID,
      postFilters.sortByDate,
    ]),
    TYPES.post.page
  );

  Object.assign(postData, data);
}

function showSearchWords(text) {
  if (route.query.search) {
    return POSTS_SERVICE.showSearchedText(text, route.query.search);
  }
  return text;
}

onMounted(async () => {
  try {
    await getPost();
    changeDocumentTitle("Публічна інформація");
  } catch (error) {
    console.error(error);
  }
});
</script>
