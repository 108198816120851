import instance from "../instance";
import router from "@/router";

import auth from "./auth";
import users from "./users";
import posts from "./posts";
import sections from "./sections";

import AUTH_SERVICE from "@/services/admin/auth";

const ADMIN_API_URL = process.env.VUE_APP_API_ADMIN_URL;

if (localStorage.getItem(process.env.VUE_APP_TOKEN_NAME) !== null) {
  instance.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
    process.env.VUE_APP_TOKEN_NAME
  )}`;
}

instance.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    if (error.response.status === 401) {
      await AUTH_SERVICE.signOutAdmin();
      router.push({ name: "admin-auth" });
    }

    return Promise.reject(error);
  }
);

export default {
  auth: auth(ADMIN_API_URL, instance),
  users: users(ADMIN_API_URL, instance),
  posts: posts(ADMIN_API_URL, instance),
  sections: sections(ADMIN_API_URL, instance),
};
