import api from "@/api/public/index";

export default {
  async sendFeedback(feedbackData, files) {
    let feedbackFormData = new FormData();

    feedbackFormData.append("data", JSON.stringify(feedbackData));

    if (files) {
      files.forEach((file) => {
        feedbackFormData.append("files", file);
      });
    }

    try {
      await api.feedback.sendFeedback(feedbackFormData);
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
};
