<template>
  <div class="manager__card main-manager__card">
    <img v-if="manager.img" :src="manager.img" class="manager__photo" />
    <h2 class="manager__fullname">{{ manager.title }}</h2>
    <p class="manager__post">{{ manager.short_text }}</p>
    <div
      v-if="social"
      class="flex justify-content-center gap-2 manager__social-links"
    >
      <a v-for="link in social" :key="link.type" :href="link.value">
        <i :class="link.type"></i>
      </a>
    </div>
    <div v-if="contacts" class="manager__contacts">
      <a
        v-for="contact in contacts"
        :key="contact.type"
        :href="(contact.type === 'phone' ? 'tel:' : 'mailto:') + contact.value"
      >
        <span>{{ contact.value }}</span>
      </a>
    </div>
  </div>
</template>

<script setup>
import { defineProps, computed } from "vue";

const SOCIAL_TYPES_LINK = ["instagram", "facebook", "viber", "telegram"];
const CONTACT_TYPES_LINK = ["email", "phone"];

const props = defineProps({
  manager: Object,
});

const options = computed(() => {
  if (props.manager.options) {
    return JSON.parse(props.manager.options);
  }

  return null;
});

const social = computed(() => {
  if (options.value?.contacts) {
    return options.value.contacts.filter((link) =>
      SOCIAL_TYPES_LINK.some((item) => item === link.type)
    );
  }

  return null;
});

const contacts = computed(() => {
  if (options.value?.contacts) {
    return options.value.contacts.filter((link) =>
      CONTACT_TYPES_LINK.some((item) => item === link.type)
    );
  }

  return null;
});
</script>
