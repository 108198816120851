export default function (instance) {
  return {
    sendFeedback: (payload) =>
      instance.post("api/feedback", payload, {
        headers: {
          "Content-type": "multipart/form-data",
        },
      }),
  };
}
