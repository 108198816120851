import HomeView from "../views/public/HomeView.vue";
import AllNewsView from "../views/public/AllNewsView.vue";
import NewsView from "../views/public/NewsView.vue";
import FeedbackView from "../views/public/FeedbackView.vue";
import ContactsView from "../views/public/ContactsView.vue";
import SingleView from "../views/public/SingleView.vue";
import SearchView from "../views/public/SearchView.vue";
import PublicInfoView from "../views/public/PublicInfoView.vue";
import ManagementView from "../views/public/ManagementView.vue";
import SectionView from "../views/public/SectionView.vue";

export default [
  {
    path: "",
    name: "home",
    component: HomeView,
  },
  {
    path: "news/:group",
    name: "all-news",
    component: AllNewsView,
  },
  {
    path: "news/:group/:url",
    name: "news",
    component: NewsView,
  },
  {
    path: "contacts",
    name: "contacts",
    component: ContactsView,
    meta: {
      breadcrumb: [{ label: "Контакти" }],
    },
  },
  {
    path: "contacts/feedback",
    name: "feedback",
    component: FeedbackView,
    meta: {
      breadcrumb: [
        {
          label: "Контакти",
          to: {
            name: "contacts",
          },
        },
        { label: "Звернення громадян" },
      ],
    },
  },
  {
    path: ":section",
    name: "section",
    component: SectionView,
  },
  {
    path: ":section/:page",
    name: "single",
    component: SingleView,
  },
  {
    path: "search",
    name: "search",
    component: SearchView,
  },
  {
    path: "public",
    name: "public",
    meta: {
      breadcrumb: [
        {
          label: "Публічна інформація",
        },
      ],
    },
    component: PublicInfoView,
  },
  {
    path: "about/kerivnitstvo",
    name: "management",
    component: ManagementView,
  },
];
