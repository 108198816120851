<template>
  <PageBreadcrumb :lastBreadcrumb="lastBreadcrumb" />

  <main class="all-news-page">
    <section class="section section__all-news">
      <div class="p-container">
        <h2 class="section__header content__header hidden lg:block">
          {{ subSection?.title }}
        </h2>
        <div v-if="newsData.rows" class="grid">
          <div
            v-for="news in newsData.rows"
            :key="news.id"
            class="col-12 md:col-6 lg:col-4"
          >
            <PageLink
              :isExternal="news.url_is_external"
              :url="POSTS_SERVICE.createURL(news)"
            >
              <NewsCard :news="news" />
            </PageLink>
          </div>
        </div>
      </div>
    </section>
    <PagePaginator
      v-if="newsData.rows"
      v-model:filters="postFilters.filters"
      :totalRows="newsData.total_rows"
      @getItemsByPage="getPost(route.params.group)"
    />
  </main>
</template>

<script setup>
import { onMounted, reactive, watch, ref, computed } from "vue";
import { useRoute } from "vue-router";

import { createFilters } from "@/helpers/filters";
import { changeDocumentTitle } from "@/helpers/site";

import PagePaginator from "@/components/public/page/PagePaginator.vue";
import PageBreadcrumb from "@/components/public/page/PageBreadcrumb.vue";
import NewsCard from "@/components/public/content/news/NewsCard.vue";
import PageLink from "@/components/public/page/PageLink.vue";

import TYPES from "@/config/types.yml";
import FILTERS from "@/config/filters.yml";

import SECTIONS_SERVICE from "@/services/public/sections";
import POSTS_SERVICE from "@/services/public/posts";

const route = useRoute();
watch(
  () => route.params.group,
  async (newVal) => {
    await getPost(newVal);
  }
);

// SECTION
const sectionData = ref(null);
const sectionFilters = reactive({
  filterBySectionID: {
    field: FILTERS.sections.filterBySectionID,
    value: TYPES.section.news,
  },
});
const subSection = computed(() => {
  if (sectionData.value) {
    return sectionData.value[0].sub_sections.find(
      (subsection) => subsection.url === route.params.group
    );
  }

  return null;
});

// NEWS
const newsData = reactive({});
const postFilters = reactive({
  filterByTypeID: {
    field: FILTERS.posts.filterByTypeID,
    value: TYPES.post.news,
  },
  filterBySectionID: {
    field: FILTERS.posts.filterBySectionID,
    value: TYPES.section.news,
  },
  filterBySubSectionID: {
    field: FILTERS.posts.filterBySubSectionID,
    value: null,
  },
  filterByPublished: {
    field: FILTERS.posts.filterByPublished,
    value: 1,
  },
  sortByDate: {
    field: FILTERS.posts.sortByDate,
    sort: FILTERS.sorting.DESC,
  },
  filters: {
    pageSize: 12,
  },
});

async function getPost(group) {
  if (sectionData.value) {
    let subsection = sectionData.value[0].sub_sections.find(
      (subsection) => subsection.url === group
    );

    if (subsection) {
      postFilters.filterBySubSectionID.value = subsection.id;

      Object.assign(
        newsData,
        await POSTS_SERVICE.getPostsData(
          createFilters(postFilters.filters, [
            postFilters.filterByPublished,
            postFilters.filterByTypeID,
            postFilters.filterBySectionID,
            postFilters.filterBySubSectionID,
            postFilters.sortByDate,
          ])
        )
      );

      changeDocumentTitle(subsection.title);
    }
  }
}

onMounted(async () => {
  try {
    sectionData.value = await SECTIONS_SERVICE.getSectionsData(
      createFilters({}, [sectionFilters.filterBySectionID])
    );

    await getPost(route.params.group);
  } catch (error) {
    console.error(error);
  }
});

// BREADCRUMB
const lastBreadcrumb = computed(() => {
  if (sectionData.value && subSection.value) {
    return [
      { label: sectionData.value[0].name, to: sectionData.value[0].url },
      { label: subSection.value.title },
    ];
  }

  return {};
});
</script>
