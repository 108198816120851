<template>
  <div class="image-upload__form">
    <div class="text-center">
      <h4 class="mb-2">{{ title }}</h4>
      <Image
        v-if="(imgSrc && imgSrc !== '') || imgSrcLocal"
        class="image-upload__preview"
        preview
        :src="imgSrcLocal ? imgSrcLocal : imgSrc ? siteURL + imgSrc : ''"
      />
    </div>
    <div class="text-center mt-2">
      <div class="image-upload__file file--upload" :key="uploadKey">
        <label class="w-full" :for="id"> Прикріпити зображення </label>
        <input :id="id" type="file" @change="(e) => uploadIMG(e)" />
      </div>
      <Button
        class="mt-2"
        label="Видалити зображення"
        size="small"
        :disabled="!imgSrc && !imgSrcLocal"
        @click="removeIMG(imgSrc)"
      />
    </div>
  </div>
</template>

<script setup>
import { showUserNotify } from "@/helpers/notify";
import { defineProps, defineEmits, ref } from "vue";

import Image from "primevue/image";
import Button from "primevue/button";

import POSTS_SERVICE from "@/services/admin/posts";

const props = defineProps({
  img: [File, String],
  title: String,
  id: String,
  imgSrc: String,
});
const emits = defineEmits(["update:img", "update:imgSrc", "remove"]);

// IMG
const siteURL = process.env.VUE_APP_API_URL;
const imgSrcLocal = ref(null);
const uploadKey = ref(0);

function uploadIMG(e) {
  if (!e.target.files.length) return;

  let reader = new FileReader();

  let file = e.target.files[0];

  reader.addEventListener(
    "load",
    function () {
      imgSrcLocal.value = reader.result;
    }.bind(this),
    false
  );

  if (file) {
    if (/\.(jpe?g|png|gif|svg|webp)$/i.test(file.name)) {
      reader.readAsDataURL(file);
    } else {
      showUserNotify("error", "Невірний формат зображення!");
      return;
    }
  }
  emits("update:img", file);
}

async function removeIMG(img) {
  if (img) {
    let formData = new FormData();
    formData.append("path", img);

    try {
      await POSTS_SERVICE.removeIMG(formData);
      showUserNotify("success", "Зображення видалено");

      emits("update:imgSrc", null);
    } catch (error) {
      console.error(error);
    }
  } else {
    imgSrcLocal.value = null;
    uploadKey.value++;
  }
}
</script>
