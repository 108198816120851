<template>
  <button
    class="scroll-top-btn"
    :style="`opacity: ${opacity}`"
    @click="scrollTop"
  ></button>
</template>

<script setup>
import { ref, onMounted } from "vue";

const opacity = ref(0);
function scrollTop() {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
}

function createOpacity() {
  let tempOpacity = window.scrollY / window.innerHeight;
  opacity.value = tempOpacity > 1 ? 1 : tempOpacity;
}

onMounted(() => {
  document.addEventListener("scroll", createOpacity);
});
</script>
