function createFilters(oldFilters, filtersArray, isRange = false) {
  let newFilters = {};

  // page setting
  newFilters.page = oldFilters.page ? oldFilters.page : 1;
  if (oldFilters.pageSize) {
    if (oldFilters.pageSize < 10 && oldFilters.pageSize !== -1) {
      newFilters.pageSize = 10;
    } else if (oldFilters.pageSize && oldFilters.pageSize > 90) {
      newFilters.pageSize = 90;
    } else {
      newFilters.pageSize = oldFilters.pageSize;
    }
  } else {
    newFilters.pageSize = 10;
  }

  //create filter fields
  newFilters.filtered = oldFilters?.filtered ? oldFilters.filtered : [];

  if (filtersArray?.length > 0) {
    if (isRange) {
      newFilters.filtered = filtersArray;
    } else {
      filtersArray.forEach((filter) => {
        let index = newFilters.filtered.findIndex(
          (item) => item.field === filter.field
        );

        if (index !== -1) {
          if (filter.value === null || filter.value === "") {
            newFilters.filtered.splice(index, 1);
          } else {
            newFilters.filtered[index] = filter;
          }
        } else if (filter.value !== null && filter.value !== "") {
          newFilters.filtered.push(filter);
        }
      });
    }
  }

  return newFilters;
}

function clearFiltersList(filtersName) {
  if (filtersName) {
    removeFiltersInStorage(filtersName);
  }

  return {
    page: 1,
    pageSize: 20,
    filtered: [],
  };
}

function savingFiltersInStorage(filters, name) {
  localStorage.setItem(name, JSON.stringify(filters));
}

function removeFiltersInStorage(name) {
  localStorage.removeItem(name);
}

export { createFilters, clearFiltersList, savingFiltersInStorage };
