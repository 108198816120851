import { createApp } from "vue";
import { createPinia } from "pinia";
import { createHead } from "@unhead/vue";

import App from "./App.vue";
import router from "./router";
import PrimeVue from "primevue/config";
import Tooltip from "primevue/tooltip";
import DialogService from "primevue/dialogservice";
import ConfirmationService from "primevue/confirmationservice";
import ToastService from "primevue/toastservice";

import "primevue/resources/themes/lara-light-indigo/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";

import "../src/assets/scss/styles.scss";

const pinia = createPinia();
const head = createHead();

const app = createApp(App)
  .use(router)
  .use(pinia)
  .use(head)
  .use(PrimeVue)
  .use(DialogService)
  .use(ConfirmationService)
  .use(ToastService)
  .directive("tooltip", Tooltip);

app.mount("#app");

export default app;
