export default function (adminURL, instance) {
  return {
    getPostsData: (payload) => instance.post(adminURL + "post/all", payload),
    getPostsTypes: (payload) => instance.post(adminURL + "post/types", payload),
    createPost: (payload) =>
      instance.post(adminURL + "post/create", payload, {
        headers: {
          "Content-type": "multipart/form-data",
        },
      }),
    updatePost: (id, payload) =>
      instance.put(adminURL + `post/${id}`, payload, {
        headers: {
          "Content-type": "multipart/form-data",
        },
      }),
    removePost: (id) => instance.delete(adminURL + `post/${id}`),
    removeIMG: (payload) =>
      instance.delete(
        adminURL + "delimg",
        { data: payload },
        {
          headers: {
            "Content-type": "multipart/form-data",
          },
        }
      ),
  };
}
