<template>
  <div class="mt-3">
    <form
      class="py-2"
      @submit.prevent="
        emits('update', {
          user: userData,
          dialog: dialog,
        })
      "
    >
      <div class="p-fluid grid">
        <div class="col-12 md:col-4 pb-0">
          <span class="p-float-label">
            <InputText
              id="surname"
              type="text"
              :class="userData.surname ? '' : 'p-invalid'"
              v-model="userData.surname"
            />
            <label for="surname">Прізвище</label>
          </span>
        </div>
        <div class="col-12 md:col-4 pb-0">
          <span class="p-float-label">
            <InputText
              id="firstname"
              type="text"
              v-model="userData.firstname"
              :class="userData.firstname ? '' : 'p-invalid'"
            />
            <label for="firstname">Ім'я</label>
          </span>
        </div>
        <div class="col-12 md:col-4 pb-0">
          <span class="p-float-label">
            <InputText id="lastname" type="text" v-model="userData.lastname" />
            <label for="lastname">По батькові</label>
          </span>
        </div>
      </div>
      <Divider type="solid" class="my-4" />
      <div class="p-fluid grid">
        <div class="col-12 md:col-4 pb-0">
          <span class="p-float-label">
            <InputText
              id="login"
              type="text"
              v-model="userData.login"
              :class="userData.login ? '' : 'p-invalid'"
            />
            <label for="login">Логін</label>
          </span>
        </div>
        <div class="col-12 md:col-4 pb-0">
          <span class="p-float-label">
            <InputText id="password" type="text" v-model="userData.password" />
            <label for="password">Пароль</label>
          </span>
        </div>
        <div class="col-12 md:col-4 pb-0">
          <Dropdown
            optionLabel="name"
            optionValue="id"
            placeholder="Оберіть групу"
            v-model="userData.id_group"
            :class="userData.id_group ? '' : 'p-invalid'"
            :options="groups"
          />
        </div>
      </div>
      <Divider type="solid" />
      <div class="p-fluid grid">
        <div class="col-12 md:col-6 pb-0">
          <ToggleButton
            v-model="isActive"
            onLabel="Користувач активний"
            offLabel="Користувач неактивний"
          />
        </div>
      </div>
      <div class="form-footer text-right">
        <Button
          autofocus
          icon="pi pi-times"
          label="Закрити"
          @click="dialog.close()"
        />
        <Button
          type="submit"
          icon="pi pi-check"
          class="ml-2 p-button-warning"
          label="Зберегти"
          :disabled="
            !userData.firstname ||
            !userData.surname ||
            !userData.login ||
            !userData.id_group
          "
        />
      </div>
    </form>
  </div>
</template>

<script setup>
import { defineEmits, watch, onMounted, ref, reactive, inject } from "vue";

import InputText from "primevue/inputtext";
import Divider from "primevue/divider";
import Dropdown from "primevue/dropdown";
import Button from "primevue/button";
import ToggleButton from "primevue/togglebutton";

import USERS_SERVICE from "@/services/admin/users";

const dialog = inject("dialogRef");

const emits = defineEmits(["update"]);

// USER
const userData = reactive({
  id_group: null,
  login: null,
  password: null,
  surname: null,
  firstname: null,
  lastname: null,
  is_active: null,
});
const isActive = ref(false);

watch(isActive, (val) => {
  userData.is_active = val ? 1 : 0;
});

onMounted(async () => {
  let dialogData = dialog.value.data;

  if (dialogData.user?.id) {
    Object.assign(userData, dialogData.user);

    isActive.value = !!userData.is_active;
    userData.password = "";
  }
});

// GROUPS
const groups = ref([]);
onMounted(async () => {
  groups.value = await USERS_SERVICE.getGroupsData();
});
</script>
