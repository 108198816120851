import app from "../main";

const DIALOG_DEFAULT_PROPS = {
  modal: true,
  draggable: false,
  style: {
    width: "80vw",
    "max-width": "1024px",
  },
};

function clickToCloseOutside(ev, dialog) {
  if (
    ev.srcElement.classList.value
      .split(" ")
      .some((el) => el === "p-dialog-mask")
  ) {
    if (dialog) {
      dialog.close();
    } else {
      app.config.globalProperties.$confirm.close();
    }
  }
}

function showModal(component, payload) {
  if (payload.props) {
    let tempStyles = Object.assign(
      DIALOG_DEFAULT_PROPS.style,
      payload.props?.style
    );

    payload.props = Object.assign(DIALOG_DEFAULT_PROPS, payload.props);
    payload.props.style = tempStyles;
  }

  const dialog = app.config.globalProperties.$dialog.open(component, payload);

  document.addEventListener("click", (ev) => clickToCloseOutside(ev, dialog));

  return dialog;
}

function showRemoveDialog(title, acceptFunc, optionFunc) {
  document.addEventListener("click", (ev) => clickToCloseOutside(ev));

  app.config.globalProperties.$confirm.require({
    message: "Ви підтверджеєте видалення?",
    header: title + "?",
    icon: "pi pi-info-circle",
    acceptClass: "p-button p-component p-button-danger",
    acceptLabel: "Так",
    rejectClass: "p-button p-component p-button-info",
    rejectLabel: "Ні",
    accept: async () => {
      await acceptFunc();

      if (optionFunc) {
        optionFunc();
      }
    },
    defaultFocus: "reject",
  });
}

function showConfirmDialog(message, header) {
  let confirmPromise = new Promise((resolve, reject) => {
    app.config.globalProperties.$confirm.require({
      message: message,
      header: header,
      icon: "pi pi-info-circle",
      acceptClass: "p-button-danger",
      acceptLabel: "Так",
      rejectLabel: "Ні",
      defaultFocus: "reject",
      accept: () => resolve(),
      reject: () => reject(),
    });
  });

  return confirmPromise;
}

export { showModal, showRemoveDialog, showConfirmDialog };
