import AUTH_SERVICE from "@/services/admin/auth";

export default {
  getAccessPermission(to, next) {
    const IS_ADMIN = AUTH_SERVICE.isAdmin();

    if (to.matched.some((record) => record.meta.requiresAdminAuth)) {
      if (AUTH_SERVICE.adminTokenIsValid()) {
        if (to.meta.isAdmin) {
          IS_ADMIN ? next() : next({ name: "admin-news" });
          return;
        }

        next();
      } else {
        next({ name: "admin-auth" });
      }
    } else {
      next();
    }
  },
};
