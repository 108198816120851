<template>
  <Paginator
    class="site-paginator"
    :rows="12"
    :totalRecords="totalRows"
    @page="changePage"
  />
</template>

<script setup>
import { defineProps, defineEmits } from "vue";

import Paginator from "primevue/paginator";

const props = defineProps({
  filters: Object,
  totalRows: Number,
});
const emits = defineEmits(["getItemsByPage", "update:filters"]);

function changePage(event) {
  let filters = { ...props.filters };

  filters.page = event.page + 1;
  filters.pageSize = event.rows;

  window.scrollTo({ top: 0, behavior: "smooth" });

  emits("update:filters", filters);
  emits("getItemsByPage");
}
</script>
