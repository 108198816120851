import api from "@/api/public/index";

export default {
  async getItemsBySearchQuery(payload) {
    try {
      let data = (await api.search.getItemsBySearchQuery(payload)).data;

      return data;
    } catch (error) {
      throw error;
    }
  },
};
