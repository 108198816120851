<template>
  <footer class="page-footer relative text-center md:text-left">
    <div class="p-container">
      <PageSiteMap />

      <div class="grid">
        <div class="col-12 md:col-6">
          <div
            class="page-footer__site-logo site-logo md:flex align-items-center justify-content-start"
          >
            <img
              class="site-logo__img"
              src="@/assets/img/emblem.svg"
              alt=""
              srcset=""
            />
            <span class="site-logo__title">
              Управління охорони здоров'я Черкаської обласної державної
              адміністрації
            </span>
          </div>
        </div>
        <div v-if="footerContacts" class="col-12 md:col-6">
          <div class="page-footer__contacts md:flex justify-content-between">
            <div
              v-for="contact in footerContacts"
              :class="['contact', contact.id_custom]"
              :key="contact.id"
            >
              <h4>{{ contact.title }}</h4>
              <a
                :href="
                  (contact.id_custom === SITE_PHONE_ID ? 'tel:' : 'mailto:') +
                  contact.url
                "
              >
                {{ contact.url }}
              </a>
            </div>
          </div>
        </div>
      </div>
      <hr class="page-footer__divider" />
      <div class="grid">
        <div class="col-12 md:col-6">
          <div
            class="page-footer__info-wrapper md:flex align-items-center justify-content-start"
          >
            <img src="@/assets/img/gerb.svg" />
            <span>Власність Управління охорони здоров’я. </span>
          </div>
        </div>
        <div class="col-12 md:col-6">
          <div
            class="page-footer__info-wrapper md:flex align-items-center justify-content-end"
          >
            <VisualImpairment />
          </div>
        </div>
      </div>
      <div class="grid">
        <div class="col-12 md:col-6">
          <div
            class="page-footer__info-wrapper md:flex align-items-center justify-content-start"
          >
            <img src="@/assets/img/cc-logo.svg" />

            <span>
              Увесь вміст доступний за ліцензією
              <a
                target="_blank"
                href="https://creativecommons.org/licenses/by/4.0/deed.uk"
              >
                Creative Commons Attribution 4.0 International license</a
              >, якщо не зазначено інше
            </span>
          </div>
        </div>
        <div class="col-12 md:col-6">
          <div
            class="page-footer__info-wrapper md:flex align-items-center justify-content-end"
          >
            <a
              class="mclaut-logo md:flex align-items-center"
              href="https://mclaut.com/"
            >
              <img src="@/assets/img/mclaut_logo.png" />
              <span>Сайт був розроблений за підтримки компанії Маклаут </span>
            </a>
          </div>
        </div>
      </div>
    </div>

    <PageScrollTopBtn />
  </footer>
</template>

<script setup>
import { inject, computed } from "vue";

import VisualImpairment from "./VisualImpairment.vue";
import PageSiteMap from "./PageSiteMap.vue";
import PageScrollTopBtn from "./PageScrollTopBtn.vue";

const SITE_EMAIL_ID = "site_email";
const SITE_PHONE_ID = "site_phone";

// SITE CONTACTS
const siteContacts = inject("siteContacts");
const footerContacts = computed(() =>
  siteContacts.value.filter(
    (item) =>
      item.id_custom === SITE_EMAIL_ID || item.id_custom === SITE_PHONE_ID
  )
);
</script>
