<template>
  <nav class="navbar">
    <ul
      v-if="menuItems"
      class="navbar__list lg:flex justify-content-between align-items-center"
    >
      <li
        v-for="item in menuItems"
        :key="item.id"
        :class="`navbar__item ${
          item.sub_sections ? 'submenu-parent flex' : ''
        } ${activeNavbarItemID === item.id ? 'submenu-parent--active' : ''}`"
      >
        <div v-if="item.sub_sections" class="navbar-item__wrapper w-full">
          <button
            class="navbar-item__btn flex align-items-center"
            @click="openSubmenuList(item.id)"
          >
            <img class="navbar-item__icon" :src="getImgUrl(item.icon)" />
            <span>
              {{ item.name }}
            </span>
          </button>
          <div
            v-if="activeNavbarItemID === item.id"
            ref="submenuWrapper"
            class="submenu-wrapper"
          >
            <div class="p-container submenu-container">
              <ul class="submenu__list grid">
                <li
                  v-for="subsection in item.sub_sections"
                  class="submenu-item col-12 lg:col-3"
                  :key="subsection.id"
                >
                  <a
                    v-if="!!subsection.url_is_external"
                    class="submenu-item__link block h-full"
                    :href="subsection.url"
                  >
                    <span>{{ subsection.title }}</span>
                  </a>
                  <router-link
                    v-else
                    class="submenu-item__link block h-full"
                    :to="`${item.url}/${subsection.url}`"
                    @click="emits('update:showMobileMenu', false)"
                  >
                    <span>{{ subsection.title }}</span>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div v-else class="flex">
          <router-link
            class="navbar-item__link flex align-items-center"
            :to="item.url"
            @click="emits('update:showMobileMenu', false)"
          >
            <img class="navbar-item__icon" :src="getImgUrl(item.icon)" />
            <span>{{ item.name }}</span>
          </router-link>
        </div>
      </li>
    </ul>
    <VisualImpairment class="lg:hidden" />
  </nav>
</template>

<script setup>
import { ref, watch, defineEmits, inject } from "vue";

import VisualImpairment from "@/components/public/page/VisualImpairment.vue";

const emits = defineEmits(["update:showMobileMenu"]);

// NAV
const menuItems = inject("siteMenuItems");
const activeNavbarItemID = ref(false);
const submenuWrapper = ref(null);

watch(activeNavbarItemID, (newVal) => {
  if (newVal) {
    document.addEventListener("click", closeSubmenuListOnClickOutside);
  } else {
    document.removeEventListener("click", closeSubmenuListOnClickOutside);
  }
});

function openSubmenuList(navbarID) {
  if (navbarID !== activeNavbarItemID.value) {
    activeNavbarItemID.value = navbarID;
  } else {
    activeNavbarItemID.value = null;
  }
}

function closeSubmenuListOnClickOutside(ev) {
  if (
    ev.target.parentElement &&
    !ev.target.parentElement.classList.value
      .split(" ")
      .some(
        (el) =>
          el === "submenu-wrapper" ||
          el === "navbar-item__wrapper" ||
          el === "navbar-item__btn" ||
          el === "submenu-container"
      )
  ) {
    activeNavbarItemID.value = null;
  }
}

function getImgUrl(img) {
  return require(`@/assets/img/${img}`);
}
</script>
