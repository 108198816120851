<template>
  <div class="visual-impairment">
    <button
      class="visual-impairment__btn"
      @click="toggleEnableVisualImpairment()"
    >
      <span>
        {{ enableFilter ? "Стандартна версія" : "Людям із порушенням зору" }}
      </span>
    </button>
    <div v-show="enableFilter" class="font-size-control">
      <div
        class="flex align-items-center justify-content-center md:justify-content-end"
      >
        <button
          :disabled="fontSettingStep.fontSize === MAX_FONT_SIZE_STEP"
          @click="
            changeFontSizeProcess(INCREMENT_PROCESS), fontSettingStep.fontSize++
          "
        >
          А+
        </button>
        <button
          :disabled="fontSettingStep.fontSize === MIN_FONT_SIZE_STEP"
          @click="
            changeFontSizeProcess(DECREMENT_PROCESS), fontSettingStep.fontSize--
          "
        >
          А-
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, onMounted, computed } from "vue";
import { useAppStore } from "@/store/app";

const appStore = useAppStore();

const VISUAL_IMPAIRMENT_FILTER_CLASS = "visual-impairment-filter";
const MAX_FONT_SIZE_STEP = 5;
const MIN_FONT_SIZE_STEP = -5;
const INCREMENT_PROCESS = "increment";
const DECREMENT_PROCESS = "decrement";

const body = document.getElementsByTagName("body")[0];

const enableFilter = computed(() => appStore.enableFilter);

const enableVisualImpairment = ref(false);
const fontSettingStep = reactive({
  fontSize: 0,
  lineHeight: 0,
});

const FONT_SIZE = {
  name: "font-size",
  value: 1,
};

function toggleEnableVisualImpairment() {
  enableVisualImpairment.value = !appStore.enableFilter;
  appStore.enableFilter = enableVisualImpairment.value;

  if (enableVisualImpairment.value) {
    body.classList.add(VISUAL_IMPAIRMENT_FILTER_CLASS);
  } else {
    body.classList.remove(VISUAL_IMPAIRMENT_FILTER_CLASS);
    fontSettingStep.fontSize = 0;
    fontSettingStep.lineHeight = 0;

    changeFontSizeProcess(null);
  }
}

function changeFontSizeInElement(type, el, value) {
  return type === INCREMENT_PROCESS
    ? parseFloat(getStylePropertyValue(el, FONT_SIZE.name)) + value + "px"
    : parseFloat(getStylePropertyValue(el, FONT_SIZE.name)) - value + "px";
}

function changeFontSizeProcess(type) {
  for (let el of document.body.getElementsByTagName("*")) {
    if (type !== null) {
      el.style.fontSize = changeFontSizeInElement(type, el, FONT_SIZE.value);
    } else {
      el.style.fontSize = "";
    }
  }
}

function getStylePropertyValue(el, property) {
  return window.getComputedStyle(el, null).getPropertyValue(property);
}

onMounted(() => {
  enableVisualImpairment.value = body.classList.value
    .split(" ")
    .some((el) => el === VISUAL_IMPAIRMENT_FILTER_CLASS);
});
</script>
