<template>
  <div class="page-breadcrumb">
    <div class="p-container">
      <Breadcrumb :home="HOME" :model="breadcrumb">
        <template #item="{ item }">
          <router-link v-if="item.to" :to="item.to" :class="item.icon">
            {{ item.label }}
          </router-link>
          <span v-else>{{ item.label }}</span>
        </template>
        <template #separator>
          <span>/</span>
        </template>
      </Breadcrumb>
    </div>
  </div>
</template>

<script setup>
import { computed, defineProps } from "vue";
import { useRoute } from "vue-router";

import Breadcrumb from "primevue/breadcrumb";

const route = useRoute();

const props = defineProps({
  lastBreadcrumb: [Object, Array],
});

const HOME = { label: "Головна", to: { name: "home" } };

const breadcrumb = computed(() => {
  let breadcrumb = route.meta.breadcrumb ? [...route.meta.breadcrumb] : [];

  if (props.lastBreadcrumb) {
    if (Array.isArray(props.lastBreadcrumb)) {
      breadcrumb.push(...props.lastBreadcrumb);
    } else {
      breadcrumb.push(props.lastBreadcrumb);
    }

    return breadcrumb;
  }

  return breadcrumb;
});
</script>
