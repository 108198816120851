<template>
  <DataTable
    v-model:editingRows="editingRows"
    rowHover
    stripedRows
    dataKey="id"
    filterDisplay="row"
    class="p-datatable-sm"
    editMode="row"
    :value="contactItems"
    @rowEditSave="editContact"
    @rowEditCancel="removeContact"
  >
    <template #empty> Дані відсутні </template>
    <template #header>
      <div
        class="flex flex-wrap align-items-center justify-content-between px-2"
      >
        <span class="table-header">Контакти</span>
        <Button
          rounded
          raised
          icon="pi pi-plus"
          severity="info"
          v-tooltip.bottom="'Додати контакт'"
          @click="createContact"
        />
      </div>
    </template>
    <Column field="value" header="Контакт">
      <template #body="{ data }">
        <span :style="data.value ? '' : 'color: var(--red-300)'">
          <InputText
            :value="data.value ? data.value : 'Введіть контакт'"
            disabled
          />
        </span>
      </template>
      <template #editor="{ data, field }">
        <InputText v-model="data[field]" />
      </template>
    </Column>
    <Column field="type" header="Тип">
      <template #body="{ data }">
        <span :style="data.name ? '' : 'color: var(--red-300)'">
          {{ data.name ? data.name : "Оберіть тип контакту" }}
        </span>
      </template>
      <template #editor="{ data, field }">
        <Dropdown
          v-model="data[field]"
          optionLabel="name"
          optionValue="id"
          placeholder="Оберіть тип"
          class="w-full"
          :options="contactTypes"
        />
      </template>
    </Column>
    <Column
      style="width: 10%; min-width: 8rem"
      bodyStyle="text-align:center"
      :rowEditor="true"
    >
      <template #roweditorsaveicon>
        <i class="pi pi-save" v-tooltip.bottom="'Зберегти'"></i>
      </template>
      <template #roweditorcancelicon>
        <i class="pi pi-trash" v-tooltip.bottom="'Видалити'"></i>
      </template>
    </Column>
  </DataTable>
</template>

<script setup>
import { ref, defineProps, defineEmits, onMounted } from "vue";

import DataTable from "primevue/datatable";
import Column from "primevue/column";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import Dropdown from "primevue/dropdown";

const props = defineProps({
  contacts: Array,
});
const emits = defineEmits(["update:contacts"]);

const editingRows = ref([]);

const contactItems = ref([]);
const contactTypes = [
  {
    id: "email",
    name: "E-Mail",
  },
  {
    id: "phone",
    name: "Телефон",
  },
  {
    id: "instagram",
    name: "Instagram",
  },
  {
    id: "facebook",
    name: "Facebook",
  },
  {
    id: "viber",
    name: "Viber",
  },
  {
    id: "telegram",
    name: "Telegram",
  },
];

function createContact() {
  contactItems.value.push({
    id: Date.now(),
    type: "",
    value: "",
    name: "",
  });

  emits("update:contacts", contactItems);
}

const editContact = (event) => {
  event.newData.name = contactTypes.find(
    (item) => item.id === event.newData.type
  ).name;
  contactItems.value[event.index] = event.newData;
};

function removeContact(emit) {
  contactItems.value.splice(emit.index, 1);
}

onMounted(() => {
  contactItems.value = props.contacts;
});
</script>
