const CHARS = {
  а: "a",
  б: "b",
  в: "v",
  г: "g",
  д: "d",
  е: "e",
  ё: "yo",
  ж: "zh",
  з: "z",
  и: "i",
  й: "y",
  к: "k",
  л: "l",
  м: "m",
  н: "n",
  о: "o",
  п: "p",
  р: "r",
  с: "s",
  т: "t",
  у: "u",
  ф: "f",
  х: "h",
  ц: "ts",
  ч: "ch",
  ш: "sh",
  щ: "sh",
  ъ: "",
  ы: "y",
  ь: "",
  э: "e",
  ю: "yu",
  я: "ya",
  є: "ye",
  і: "i",
  ї: "yi",
  ґ: "g",
};

function transliteration(str) {
  return str
    .toLowerCase()
    .split("")
    .map((char) => {
      if (CHARS[char] || CHARS[char] === "") {
        return CHARS[char];
      } else {
        return char;
      }
    })
    .join("")
    .trim()
    .replace(/[^a-z0-9]/gm, "-");
}

export { transliteration };
