<template>
  <div v-if="feedbackSuccess" class="feedback-form__success">
    <img src="@/assets/img/feedback-success.svg" alt="" />
    <h1>Заявка зареєстрована</h1>
  </div>
  <form v-else class="feedback-form" @submit.prevent="sendFeedback">
    <div class="feedback-form__field">
      <label for="fullname" class="field__title">
        Прізвище, ім'я, по батькові
      </label>
      <InputText
        required
        id="fullname"
        type="text"
        :class="[
          'field__input',
          fullnameValid || !feedbackData.fullname ? '' : 'p-invalid',
        ]"
        v-model="feedbackData.fullname"
      />
    </div>
    <div class="feedback-form__field">
      <label for="email" class="field__title">Електронна пошта</label>
      <label for="email" class="field__subtitle">
        у форматі example@email.com
      </label>
      <InputText
        required
        id="email"
        type="text"
        v-model="feedbackData.email"
        :class="[
          'field__input',
          emailIsValid || !feedbackData.email ? '' : 'p-invalid',
        ]"
      />
    </div>
    <div class="feedback-form__field">
      <label for="phone" class="field__title">Контактний телефон</label>
      <InputMask
        required
        type="text"
        id="phone"
        slotChar=""
        v-model="feedbackData.phone"
        :mask="'+380999999999'"
        :class="[
          'field__input',
          phoneIsValid || !feedbackData.phone ? '' : 'p-invalid',
        ]"
      />
    </div>
    <div class="feedback-form__field">
      <label for="address" class="field__title">Адреса</label>
      <label for="address" class="field__subtitle">
        Введіть в поле поштовий індекс, область, район, населений пункт, вулицю,
        будинок, корпус, квартиру
      </label>
      <Textarea
        id="address"
        rows="2"
        type="text"
        class="field__input"
        v-model="feedbackData.address"
      />
    </div>
    <div class="feedback-form__field">
      <label for="feedback" class="field__title">Текст звернення</label>
      <Textarea
        id="feedback"
        rows="5"
        class="field__input"
        v-model="feedbackData.text"
      />
    </div>
    <div class="feedback-form__field">
      <label for="file" class="field__subtitle">
        Допустимі формати: jpg, jpeg, doc, docx, png, pdf
      </label>
      <label for="file" class="field__subtitle">
        Допустима кількість: до 3-х файлів
      </label>
      <input
        multiple
        id="file"
        type="file"
        class="field__input"
        @change="uploadFile"
      />
      <label for="file" class="upload-file">Оберіть файли</label>
      <label v-if="fileWarning" for="file" class="field__subtitle p-invalid">
        {{ fileWarning }}
      </label>
      <ul class="feedback-form__files">
        <li v-for="file in feedbackFiles" :key="file">
          {{ file.name }}
        </li>
      </ul>
    </div>
    <div class="feedback-form__field block">
      <input id="consent" type="checkbox" v-model="feedbackConfirm" />
      <label for="consent" class="field__subtitle">
        Даю згоду на обробку і використання персональних даних згідно з
        законодавством України
      </label>
    </div>
    <button
      class="feedback-form__submit feedback-btn"
      :disabled="disableSubmit"
    >
      Відправити звернення
    </button>
  </form>
</template>

<script setup>
import { ref, reactive, computed } from "vue";

import { showUserNotify } from "@/helpers/notify";

import InputText from "primevue/inputtext";
import Textarea from "primevue/textarea";
import InputMask from "primevue/inputmask";

import FEEDBACK_SERVICE from "@/services/public/feedback";

const MAX_SIZE_FILE = 209715200; // 200 Mb
const MAX_FILES_QTT = 3;
const EMAIL_REGEX = /[^\s@]+@[^\s@]+\.[^\s@]+$/;
const PHONE_REGEX = /^(\+?380)?[0-9]{9}$/;
const FULLNAME_REGEX = /^([a-zA-Zа-яА-ЯіІїЇєЄ\s]){2,}([-]?)/;

const feedbackData = reactive({
  fullname: null,
  email: null,
  phone: null,
  address: null,
  text: null,
});
const feedbackFiles = ref([]);
const feedbackConfirm = ref(false);
const feedbackSuccess = ref(false);
const fileWarning = ref(null);

const phoneIsValid = computed(() => {
  if (feedbackData.phone) {
    return PHONE_REGEX.test(feedbackData.phone);
  }

  return true;
});

const emailIsValid = computed(() => {
  if (feedbackData.email) {
    return EMAIL_REGEX.test(feedbackData.email);
  }

  return true;
});

const fullnameValid = computed(() => {
  if (feedbackData.fullname) {
    return FULLNAME_REGEX.test(feedbackData.fullname);
  }

  return true;
});

const disableSubmit = computed(
  () =>
    !(
      feedbackData.fullname &&
      feedbackData.email &&
      feedbackData.email &&
      fullnameValid.value &&
      phoneIsValid.value &&
      emailIsValid.value &&
      feedbackConfirm.value
    )
);

async function sendFeedback() {
  try {
    await FEEDBACK_SERVICE.sendFeedback(feedbackData, feedbackFiles.value);

    feedbackSuccess.value = true;
  } catch (error) {
    showUserNotify("error", "Помилка відправки звернення - спробуйте пізніше");
  }
}

function uploadFile(e) {
  feedbackFiles.value = [];

  if (!e.target.files.length) return;

  let files = Array.from(e.target.files);
  if (files.length <= MAX_FILES_QTT) {
    files.forEach((file) => {
      if (!/\.(docx?|pdf|txt|jpe?g|png)$/i.test(file.name)) {
        fileWarning.value = "Формат не підтримується!";
        feedbackFiles.value = [];
      } else if (file.size > MAX_SIZE_FILE) {
        fileWarning.value = "Занадто великий розмір файлу!";
        feedbackFiles.value = [];
        return;
      } else {
        fileWarning.value = null;
        feedbackFiles.value.push(file);
      }
    });
  } else {
    fileWarning.value = `Кількість файлів перевищує ${MAX_FILES_QTT}`;
    feedbackFiles.value = [];
    return;
  }
}
</script>
