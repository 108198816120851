<template>
  <div class="flex gap-1 item-control-panel justify-content-center">
    <Button
      v-if="tooltipEdit"
      type="button"
      icon="pi pi-file-edit"
      class="p-button-warning p-button-rounded"
      v-tooltip.bottom="tooltipEdit"
      autofocus="true"
      @click="emits('edit')"
    >
    </Button>
    <Button
      v-if="tooltipRemove"
      type="button"
      icon="pi pi-trash"
      class="p-button-danger p-button-rounded"
      v-tooltip.bottom="tooltipRemove"
      @click="remove"
    >
    </Button>
  </div>
</template>

<script setup>
import { defineProps, defineEmits } from "vue";
import { showRemoveDialog } from "@/helpers/dialog";

import Button from "primevue/button";

const props = defineProps({
  tooltipEdit: String,
  tooltipRemove: String,
});
const emits = defineEmits(["edit", "remove"]);

function remove() {
  showRemoveDialog(props.tooltipRemove, () => {
    emits("remove");
  });
}
</script>
