<template>
  <div class="latest-news">
    <TabView
      v-if="newsSubsections"
      scrollable
      class="latest-news__tabs"
      @tabChange="changeTab"
    >
      <TabPanel
        v-for="section in newsSubsections"
        :key="section.id"
        :header="section.title"
      >
        <div v-if="latestNews" class="latest-news__list">
          <div class="latest-news__item latest-news__first hidden md:block">
            <PageLink
              :isExternal="mainLatestNews.url_is_external"
              :url="POSTS_SERVICE.createURL(mainLatestNews)"
            >
              <figure>
                <span v-if="getNewsTag(mainLatestNews)" class="news-tag">
                  {{ getNewsTag(mainLatestNews) }}
                </span>
                <img v-if="mainLatestNews.img" :src="mainLatestNews.img" />
                <img v-else src="@/assets/img/default-news.png" />

                <figcaption class="flex flex-column justify-content-end">
                  <h2>
                    {{ mainLatestNews.title }}
                  </h2>
                  <span v-html="mainLatestNews.short_text"> </span>
                </figcaption>
              </figure>
            </PageLink>
          </div>
          <div class="md:hidden">
            <PageLink
              :isExternal="mainLatestNews.url_is_external"
              :url="POSTS_SERVICE.createURL(mainLatestNews)"
            >
              <LatestNewsCard :news="mainLatestNews" />
            </PageLink>
          </div>
          <div v-for="news in latestNews" :key="news.id">
            <PageLink
              :isExternal="news.url_is_external"
              :url="POSTS_SERVICE.createURL(news)"
            >
              <LatestNewsCard :news="news" />
            </PageLink>
          </div>

          <router-link
            class="all-news__link"
            :to="{
              name: 'all-news',
              params: { group: section.url },
            }"
          >
            <span>Дивитись більше</span>
          </router-link>
        </div>
      </TabPanel>
    </TabView>
  </div>
</template>

<script setup>
import { reactive, ref, onMounted } from "vue";
import { createFilters } from "@/helpers/filters";

import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import LatestNewsCard from "./LatestNewsCard.vue";
import PageLink from "../../page/PageLink.vue";

import TYPES from "@/config/types.yml";
import FILTERS from "@/config/filters.yml";

import POSTS_SERVICE from "@/services/public/posts";
import SECTIONS_SERVICE from "@/services/public/sections";

// NEWS
const postFilters = reactive({
  filterByTypeID: {
    field: FILTERS.posts.filterByTypeID,
    value: TYPES.post.news,
  },
  filterBySectionID: {
    field: FILTERS.posts.filterBySectionID,
    value: TYPES.section.news,
  },
  filterBySubSectionID: {
    field: FILTERS.posts.filterBySubSectionID,
    value: null,
  },
  filterByPublished: {
    field: FILTERS.posts.filterByPublished,
    value: 1,
  },
  sortByDate: {
    field: FILTERS.posts.sortByDate,
    sort: FILTERS.sorting.DESC,
  },
});

function getNewsTag(news) {
  if (news.options) {
    let options = JSON.parse(news.options);
    return options.tag ? options.tag : null;
  }

  return null;
}

// SECTION
const sectionFilters = reactive({
  filterBySectionID: {
    field: FILTERS.sections.filterBySectionID,
    value: TYPES.section.news,
  },
});

// TABS
const newsSubsections = ref(null);

async function changeTab({ index }) {
  let subsectionID = newsSubsections.value[index].id;

  await getPosts(subsectionID);
}

const latestNews = ref(null);
const mainLatestNews = reactive({});

async function getPosts(subsectionID) {
  postFilters.filterBySubSectionID.value = subsectionID;

  let news = (
    await POSTS_SERVICE.getPostsData(
      createFilters({}, [
        postFilters.filterByPublished,
        postFilters.filterByTypeID,
        postFilters.filterBySectionID,
        postFilters.filterBySubSectionID,
        postFilters.sortByDate,
      ])
    )
  ).rows;

  if (news) {
    Object.assign(mainLatestNews, news.shift());
    latestNews.value = news.slice(0, 2);
  } else {
    Object.assign(mainLatestNews, {});
    latestNews.value = null;
  }
}

onMounted(async () => {
  try {
    let sectionData = await SECTIONS_SERVICE.getSectionsData(
      createFilters({}, [sectionFilters.filterBySectionID])
    );

    newsSubsections.value = sectionData[0].sub_sections;

    await getPosts(newsSubsections.value[0].id);
  } catch (error) {
    console.error(error);
  }
});
</script>
