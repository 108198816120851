<template>
  <form class="auth-form py-2" @submit.prevent="signIn">
    <div class="auth-form__field">
      <InputText
        id="login"
        v-model="user.login"
        type="text"
        placeholder="Логін"
        :class="user.login ? '' : 'p-invalid'"
      />
    </div>
    <div class="auth-form__field">
      <Password
        id="password"
        v-model="user.password"
        placeholder="Пароль"
        :feedback="false"
        :class="user.password ? '' : 'p-invalid'"
      />
    </div>
    <Button
      type="submit"
      label="Увійти"
      :disabled="!user.login || !user.password"
    />
  </form>
</template>

<script setup>
import { reactive } from "vue";
import { useRouter } from "vue-router";

import Button from "primevue/button";
import InputText from "primevue/inputtext";
import Password from "primevue/password";

import AUTH_SERVICE from "@/services/admin/auth";

const router = useRouter();

// USER
const user = reactive({
  login: null,
  password: null,
});

async function signIn() {
  try {
    await AUTH_SERVICE.signIn(user);

    router.push({ name: "admin-news" });
  } catch (error) {
    console.error(error);
  }
}
</script>
