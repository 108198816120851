<template>
  <PageBreadcrumb :lastBreadcrumb="lastBreadcrumb" />

  <main class="management-page">
    <div class="p-container">
      <section class="section">
        <h1 class="section__header content__header">Керівництво</h1>
        <div class="managers">
          <div class="grid justify-content-center">
            <div v-if="leader" class="col-12">
              <ManagerCard :manager="leader" />
            </div>
            <div
              v-for="manager in otherManagers"
              class="col-12 md:col-6"
              :key="manager.id"
            >
              <ManagerCard :manager="manager" />
            </div>
          </div>
        </div>
      </section>
    </div>
  </main>
</template>

<script setup>
import { computed, reactive, onMounted } from "vue";

import { createFilters } from "@/helpers/filters";
import { changeDocumentTitle } from "@/helpers/site";

import PageBreadcrumb from "@/components/public/page/PageBreadcrumb.vue";
import ManagerCard from "@/components/public/content/manager/ManagerCard.vue";

import TYPES from "@/config/types.yml";
import FILTERS from "@/config/filters.yml";

import POSTS_SERVICE from "@/services/public/posts";

const LEADER_TYPE = "leader";

// MANAGERS
const managersData = reactive({});
const leader = computed(() => {
  if (managersData.rows) {
    return managersData.rows.find(
      (manager) => manager.id_custom === LEADER_TYPE
    );
  }

  return null;
});

const otherManagers = computed(() => {
  if (managersData.rows) {
    return managersData.rows.filter(
      (manager) => manager.id_custom !== LEADER_TYPE
    );
  }

  return null;
});

const postFilters = reactive({
  filterByTypeID: {
    field: FILTERS.posts.filterByTypeID,
    value: TYPES.post.manager,
  },
  filterBySectionID: {
    field: FILTERS.posts.filterBySectionID,
    value: TYPES.section.about,
  },
  filterByPublished: {
    field: FILTERS.posts.filterByPublished,
    value: 1,
  },
});

async function getMangers() {
  let data = await POSTS_SERVICE.getPostsData(
    createFilters({}, [
      postFilters.filterByPublished,
      postFilters.filterByTypeID,
      postFilters.filterBySectionID,
    ]),
    TYPES.post.page
  );

  Object.assign(managersData, data);
}

onMounted(async () => {
  try {
    await getMangers();
    changeDocumentTitle("Керівництво");
  } catch (error) {
    console.error(error);
  }
});

// BREADCRUMB
const lastBreadcrumb = computed(() => {
  return [{ label: "Про Управління", to: "/about" }, { label: "Керівництво" }];
});
</script>
