import TYPES from "@/config/types.yml";

import AuthView from "@/views/admin/AuthView.vue";
import AdminView from "@/views/admin/AdminView.vue";
import PostsView from "@/views/admin/PostsView.vue";
import UsersView from "@/views/admin/UsersView.vue";
import PostEditor from "@/views/admin/PostEditor.vue";

export default [
  {
    path: "",
    name: "admin-auth",
    component: AuthView,
  },
  {
    path: "workspace",
    name: "admin-workspace",
    meta: {
      requiresAdminAuth: true,
    },
    component: AdminView,
    children: [
      {
        path: "edit/:section/:type/:id?",
        name: "admin-post-editor",
        component: PostEditor,
      },
      {
        path: "about",
        name: "admin-about",
        meta: {
          title: "Про Управління",
          section: TYPES.section.about,
          type: TYPES.post.page,
        },
        component: PostsView,
      },
      {
        path: "news",
        name: "admin-news",
        meta: {
          title: "Новини",
          section: TYPES.section.news,
          type: TYPES.post.news,
        },
        component: PostsView,
      },
      {
        path: "activity",
        name: "admin-activity",
        meta: {
          title: "Діяльність",
          section: TYPES.section.activity,
          type: TYPES.post.page,
        },
        component: PostsView,
      },
      {
        path: "public",
        name: "admin-public",
        meta: {
          title: "Публічна інформація",
          section: TYPES.section.public,
          type: TYPES.post.page,
        },
        component: PostsView,
      },
      {
        path: "contacts",
        name: "admin-contacts",
        meta: {
          title: "Контакти",
          section: TYPES.section.contact,
          type: TYPES.post.contact,
        },
        component: PostsView,
      },
      {
        path: "feedback",
        name: "admin-feedback",
        meta: {
          title: "Електронне звернення",
          section: TYPES.section.contact,
          type: TYPES.post.feedback,
        },
        component: PostsView,
      },
      {
        path: "managers",
        name: "admin-managers",
        meta: {
          title: "Керівництво",
          section: TYPES.section.about,
          type: TYPES.post.manager,
        },
        component: PostsView,
      },
      {
        path: "users",
        name: "admin-users",
        meta: {
          title: "Користувачі",
          isAdmin: true,
        },
        component: UsersView,
      },
      {
        path: "site",
        children: [
          {
            path: "sliders",
            name: "admin-site-sliders",
            meta: {
              title: "Слайдер",
              section: TYPES.section.main,
              type: TYPES.post.slider,
            },
            component: PostsView,
          },
          {
            path: "links",
            name: "admin-site-links",
            meta: {
              title: "Корисні посилання",
              section: TYPES.section.main,
              type: TYPES.post.link,
            },
            component: PostsView,
          },
          {
            path: "contacts",
            name: "admin-site-contacts",
            meta: {
              title: "Контакти",
              section: TYPES.section.main,
              type: TYPES.post.site_contact,
            },
            component: PostsView,
          },
        ],
      },
    ],
  },
];
