import { showNetworkErrorNotify } from "@/helpers/notify";

import axios from "axios";

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  withCredentials: true,
  headers: {
    accept: "application/json",
  },
});

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    showNetworkErrorNotify(error);

    throw error;
  }
);

export default instance;
