<template>
  <PageBreadcrumb />

  <main class="search-page">
    <div class="p-container">
      <section class="section">
        <h1 class="section__header content__header">Результати пошуку</h1>
        <div class="search__result">
          <p class="result__title">
            Ви шукали
            <span class="result__word">«{{ route.query.search }}»</span>.
            {{
              searchResult.rows
                ? `Знайдено матеріалів: ${searchResult.total_rows}`
                : "Нічого не знайдено."
            }}
          </p>
          <div v-if="searchResult.rows" class="result__list">
            <PageLink
              v-for="result in searchResult.rows"
              class="block result__item"
              :key="result.updated_at"
              :isExternal="result.url_is_external"
              :url="POSTS_SERVICE.createURL(result)"
              :query="route.query"
            >
              <ResultItem :result="result" />
            </PageLink>
          </div>
        </div>
      </section>
      <PagePaginator
        v-if="searchResult.rows"
        v-model:filters="searchPayload.filters"
        :key="paginatorKey"
        :totalRows="searchResult.total_rows"
        @getItemsByPage="getSearchResult(route.query.search)"
      />
    </div>
  </main>
</template>

<script setup>
import { watch, onMounted, ref, reactive } from "vue";

import { useRoute } from "vue-router";

import { changeDocumentTitle } from "@/helpers/site";

import PageBreadcrumb from "@/components/public/page/PageBreadcrumb.vue";
import PagePaginator from "@/components/public/page/PagePaginator.vue";

import ResultItem from "@/components/public/content/search/ResultItem.vue";
import PageLink from "@/components/public/page/PageLink.vue";

import SEARCH_SERVICE from "@/services/public/searching";
import POSTS_SERVICE from "@/services/public/posts";

const route = useRoute();
watch(
  () => route.query,
  async (newQuery) => {
    if (route.name === "search") {
      await getSearchResult(newQuery.search);
    }
  },
  { deep: true }
);

const paginatorKey = ref(0);

const searchResult = reactive({});
const searchPayload = reactive({
  filters: {
    page: 1,
    pageSize: 15,
    filtered: null,
  },
});

async function getSearchResult(query) {
  searchPayload.filters.filtered = [
    {
      search: query,
    },
  ];

  Object.assign(
    searchResult,
    await SEARCH_SERVICE.getItemsBySearchQuery(searchPayload.filters)
  );
}

onMounted(async () => {
  try {
    await getSearchResult(route.query.search);

    changeDocumentTitle("Пошук");
  } catch (error) {
    console.error(error);
  }
});
</script>
